const SFConstants = {

  VERSION: "1.18.16 A2A",
  APP_NAME: "SafetyForge",
  LANDING_PAGE: "/defaultlanding",
  SUPPORT_EMAIL_ADDRESS: "supporto@iotron.it",
  DEVELOPER_NAME: "Iotron S.r.l.",
  DEVELOPER_WEBSITE: "www.iotron.it",
  LOGIN_DELAY_TIME: 2, // secondi

  // ******************************************************************
  // REGEX PER VERIFICA FORMS
  // ******************************************************************

  // CE000001 - 05/02/2020 - Alberto Stefanutti
  // Inserito il carattere "/" tra quelli consentiti per i campi di un form.
  REGEX_CODICE: /^[a-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_* ]*$/,
  REGEX_NOME: /^[a-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_ ]*$/,
  REGEX_UUID_MACADDRESS: /^[a-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_: ]*$/,
  REGEX_DESCRIZIONE: /^[a-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_ ]*$/,
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq)
  REGEX_DESCRIZIONE_ESTESA: /^[a-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_:()" ]*$/,
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq) - FINE
  REGEX_PWD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[*~£$%&^]).{8,}$/,
  // REGEX_DESCRIZIONE: /^[\sa-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_ \s]*$/, // Versione che consente gli "a capo"
  // CE000001 - 05/02/2020 - Alberto Stefanutti - FINE
  // A. Stefanutti - Campo CF ambivalente CF/P.IVA (trello https://trello.com/c/jF1Qcae0)
  REGEX_PIVA: /^([0-9]{11})*$/,
  REGEX_CF: /^([A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1})*$/,
  // A. Stefanutti - Campo CF ambivalente CF/P.IVA (trello https://trello.com/c/jF1Qcae0) - FINE


  
  // ******************************************************************
  // URL delle API REST disponibili
  // ******************************************************************

  // --- GOOGLE RECAPTCHA VERIFY ---
  urlGoogleRecaptchaVerify: "/security/captcha/verify",

  // --- LOGIN ---
  urlLogin: "/servizi/accesso",

  // --- NOTIFICHE ---
  urlNotifiche_conta: "/notifiche/conta",
  urlNotifiche_lista: "/notifiche/lista",
  urlNotifiche_modifica: "/notifiche/modifica",
  opIdNotifiche_modifica: "Notifiche_edit",
  
  // --- FUNZIONALITA' ---
  urlFunzionalita_list: "/funzionalita/lista", // 1 - Lista non paginata di tutte le funzionalità
  urlFunzionalita_list_nosysadmin: "/funzionalita/nosysadmin/lista", // 2 - Lista non paginata di tutte le funzionalità con flag solosysadmin = 0
  urlFunzionalita_active_list: "/funzionalita/attive/lista", // 3 - Lista non paginata di tutte le funzionalità associate a moduli attivi
  urlFunzionalita_active_list_nosysadmin: "/funzionalita/attive/nosysadmin/lista", // 4 - Lista non paginata di tutte le funzionalità associate a moduli attivi e con flag solosysadmin = 0
  urlFunzionalita_add: "/funzionalita/aggiungi",
  urlFunzionalita_edit: "/funzionalita/modifica",
  urlFunzionalita_delete: "/funzionalita/elimina/",
  opIdFunzionalita_add: "Feature_add",
  opIdFunzionalita_edit: "Feature_edit",
  opIdFunzionalita_delete: "Feature_delete",

  // --- MODULI ---
  urlModuli_list: "/moduli/lista",
  urlModuli_add: "/moduli/aggiungi",
  urlModuli_edit: "/moduli/modifica",
  urlModuli_delete: "/moduli/elimina/",
  opIdModuli_add: "Module_add",
  opIdModuli_edit: "Module_edit",
  opIdModuli_delete: "Module_delete",
  // --- ASSOCIAZIONE MODULI-FUNZIONALITA' ---
  urlModuliFunzionalita_list: "/moduli/funzionalita/lista/u/",
  urlModuliFunzionalita_edit: "/moduli/funzionalita/modifica",
  opIdModuliFunzionalita_edit: "ModuleFeatures_edit",

  // --- RUOLI ---
  urlRuoli_list: "/ruoli/lista", // Restituisce tutti i ruoli
  urlRuoli_list_nosysadmin: "/ruoli/nosysadmin/lista", // NON restituisce i ruoli solosysadmin
  urlRuoli_list_noreserved: "/ruoli/noreserved/lista", // NON restituisce i ruoli riservati
  urlRuoli_list_nosysadmin_noreserved: "/ruoli/nosysadmin/noreserved/lista", // NON restituisce i ruoli solosysadmin e i ruoli riservati
  urlRuoli_add: "/ruoli/aggiungi",
  urlRuoli_edit: "/ruoli/modifica",
  urlRuoli_delete: "/ruoli/elimina/",
  opIdRuoli_add: "Role_add",
  opIdRuoli_edit: "Role_edit",
  opIdRuoli_delete: "Role_delete",
  // --- ASSOCIAZIONE RUOLI-FUNZIONALITA' ---
  urlRuoliFunzionalita_list: "/ruoli/funzionalita/lista/u/",
  urlRuoliFunzionalita_multipli_list: "/ruoli/funzionalita/lista/multipli", // Restituisce la lista delle funzionalità sulla base dei ruoli passati
  urlRuoliFunzionalita_edit: "/ruoli/funzionalita/modifica",
  opIdRuoliFunzionalita_edit: "RoleFeatures_edit",

  // --- PROFILI ---
  urlProfili_list: "/profili/lista", // Restituisce tutti i profili
  urlProfili_list_nosysadmin: "/profili/nosysadmin/lista", // NON restituisce i profili solosysadmin
  urlProfili_list_noreserved: "/profili/noreserved/lista", // NON restituisce i profili riservati
  urlProfili_list_nosysadmin_noreserved: "/profili/nosysadmin/noreserved/lista", // NON restituisce i profili solosysadmin e i profili riservati
  urlProfili_add: "/profili/aggiungi",
  urlProfili_edit: "/profili/modifica",
  urlProfili_delete: "/profili/elimina/",
  opIdProfili_add: "Profile_add",
  opIdProfili_edit: "Profile_edit",
  opIdProfili_delete: "Profile_delete",
  // --- ASSOCIAZIONE PROFILI-RUOLI ---
  urlProfiliRuoli_list: "/profili/ruoli/lista/u/",
  urlProfiliRuoli_edit: "/profili/ruoli/modifica",
  opIdProfiliRuoli_edit: "ProfileRoles_edit",

  // --- SOCIETA ---
  urlSocieta_list: "/societa/lista",
  urlSocieta_list_nosysadmin: "/societa/nosysadmin/lista",
  urlSocieta_assegnabili_list: "/societa/lista/assegnabili/attivita/",
  urlSocieta_assegnabili_list_nosysadmin: "/societa/nosysadmin/lista/assegnabili/attivita/",
  urlSocieta_senzaac_list: "/societa/lista/assegnabili/areacenter",
  urlSocieta_senzaac_list_nosysadmin: "/societa/nosysadmin/lista/assegnabili/areacenter",
  urlSocieta_add: "/societa/aggiungi",
  urlSocieta_edit: "/societa/modifica",
  urlSocieta_delete: "/societa/elimina/",
  opIdSocieta_add: "Societa_add",
  opIdSocieta_edit: "Societa_edit",
  opIdSocieta_delete: "Societa_delete",

  // --- UTENTI ---
  urlUtenti_list: "/utenti/lista",
  urlUtenti_list_concartacompetenze_zone: "/utenti/lista/concartacompetenze/conzone",
  urlUtenti_list_nosysadmin: "/utenti/nosysadmin/lista",
  // A. Stefanutti - Filtraggio per zone nella sezione operatività - utenti (aka "scheda FBI") - Trello https://trello.com/c/AZGEY6WP.
  urlUtenti_list_zone: "/utenti/lista/conzone",
  urlUtenti_list_nosysadmin_zone: "/utenti/nosysadmin/lista/conzone",
  // A. Stefanutti - Filtraggio per zone nella sezione operatività - utenti (aka "scheda FBI") - FINE.
  urlUtenti_list_nosysadmin_concartacompetenze_zone: "/utenti/nosysadmin/lista/concartacompetenze/conzone",
  urlUtenti_add: "/utenti/aggiungi",
  urlUtenti_add_nosysadmin: "/utenti/nosysadmin/aggiungi",
  urlUtenti_edit: "/utenti/modifica",
  urlUtenti_delete: "/utenti/elimina/",
  // A. Stefanutti - Rendere funzionante l'import massivo utenti da CSV (https://trello.com/c/ujmCB52D)
  urlUtenti_import_csv: "/utenti/import/csv",
  urlUtenti_import_csv_nosysadmin: "/utenti/nosysadmin/import/csv",
  // A. Stefanutti - Rendere funzionante l'import massivo utenti da CSV (https://trello.com/c/ujmCB52D) - FINE
  urlPassword_change: "/utenti/cambiapwd",
  urlPassword_reset: "/utenti/resetpwd",
  // A. Stefanutti - Nuova gestione password dimenticata/reset (trello https://trello.com/c/y5GEmknD)
  urlPassword_changeStep1: "/utenti/cambiapwdstep1",
  urlPassword_changeStep2: "/utenti/cambiapwdstep2",
  urlPassword_changeStep3: "/utenti/cambiapwdstep3",
  // A. Stefanutti - Nuova gestione password dimenticata/reset (trello https://trello.com/c/y5GEmknD) - FINE
  opIdUtenti_add: "User_add",
  opIdUtenti_add_nosysadmin: "User_add_nosysadmin",
  opIdUtenti_edit: "User_edit",
  opIdUtenti_delete: "User_delete",
  opIdPassword_change: "Password_change",
  opIdPassword_reset: "Password_reset",
  urlUtenti_dettaglio: "/utenti/dettaglio/u/",
  // A. Stefanutti - Gestione verbale di consegna DPI via web (trello https://trello.com/c/JpM7AcDT e correlate).
  urlUpdateVerbale: "/allegati/verbaliconsegna/sostituisci",
  opIdUpdateVerbale: "VerbaleConsegna_replace",
  // A. Stefanutti - Gestione verbale di consegna DPI via web - FINE

  // --- ASSOCIAZIONE UTENTI-ZONE
  urlUtentiZone_list: "/utenti/zone/lista/u/",
  urlUtentiZone_edit: "/utenti/zone/modifica",
  opIdUtentiZone_edit: "UtentiZone_edit",
  // --- ASSOCIAZIONE UTENTI-DPI
  urlUtentiDpi_list: "/utenti/dpi/lista/u/",
  urlUtentiDpi_edit: "/utenti/dpi/modifica",
  // A. Stefanutti - Gestione verbale di consegna DPI via web (trello https://trello.com/c/JpM7AcDT e correlate).
  urlUtentiDpi_edit_converbale: "/utenti/dpi/modifica/converbale",
  // A. Stefanutti - Gestione verbale di consegna DPI via web - FINE
  opIdUtentiDpi_edit: "UserPpes_edit",
  // --- ASSOCIAZIONE UTENTI-TIPI DI OPERAZIONE
  urlUtentiTipiOperazione_list: "/utenti/tipioperazione/lista/u/",
  urlUtentiTipiOperazione_edit: "/utenti/tipioperazione/modifica",
  opIdUtentiTipiOperazione_edit: "UtentiTipiOperazione_edit",
  // --- ASSOCIAZIONE UTENTI-LIVELLI NOTIFICA
  urlUtentiLivelliNotifica_list: "/utenti/livellinotifica/lista/u/",
  urlUtentiLivelliNotifica_edit: "/utenti/livellinotifica/modifica",
  opIdUtentiLivelliNotifica_edit: "UtentiLivelliNotifica_edit",
  // --- ASSOCIAZIONE UTENTI-CANALI NOTIFICA
  urlUtentiCanaliNotifica_list: "/utenti/canalinotifica/lista/u/",
  urlUtentiCanaliNotifica_edit: "/utenti/canalinotifica/modifica",
  opIdUtentiCanaliNotifica_edit: "UtentiCanaliNotifica_edit",

  // --- CARTE COMPETENZE ---
  urlCartecompetenze_list: "/cartecompetenze/lista",
  urlCartacompetenze_renew: "/cartecompetenze/rinnovo/",
  urlCartacompetenzeCompetenze_list: "/cartecompetenze/competenze/lista/u/",
  opIdCartacompetenze_renew: "CartaCompetenze_renew",
  opIdCartacompetenzeCompetenze_list: "CartaCompetenzeCompetenze_edit",

  // --- COMPETENZE ---
  urlCompetenze_list: "/competenze/lista",
  urlCompetenze_add: "/competenze/aggiungi",
  urlCompetenze_edit: "/competenze/modifica",
  urlCompetenze_delete: "/competenze/elimina/",
  opIdCompetenze_add: "Competenze_add",
  opIdCompetenze_edit: "Competenze_edit",
  opIdCompetenze_delete: "Competenze_delete",

  // A2A EVOLUTIVE - Req. 2.1 - A. Stefanutti
  // Differenziazione dell'assetto di riferimento di ogni utente in funzione della sua mansione.
  // --- IDONEITA' ---
  urlIdoneita_list: "/idoneita/lista",
  // A. Stefanutti - Matrice Idoneità - Tipi di DPI
  urlIdoneitaConTipiDpi_list: "/idoneita/contipidpi/lista",
  // A. Stefanutti - Matrice Idoneità - Tipi di DPI - FINE
  urlIdoneita_add: "/idoneita/aggiungi",
  urlIdoneita_edit: "/idoneita/modifica",
  urlIdoneita_delete: "/idoneita/elimina/",
  urlIdoneitaRischi_list: "/idoneita/rischi/lista/u/",
  urlIdoneitaRischi_edit: "/idoneita/rischi/modifica",
  opIdIdoneita_add: "Idoneita_add",
  opIdIdoneita_edit: "Idoneita_edit",
  opIdIdoneita_delete: "Idoneita_delete",
  opIdIdoneitaRischi_edit: "IdoneitaRischi_edit",

  // --- RISCHI ---
  urlRischi_list: "/rischi/lista",
  urlRischi_add: "/rischi/aggiungi",
  urlRischi_edit: "/rischi/modifica",
  urlRischi_delete: "/rischi/elimina/",
  opIdRischi_add: "Rischi_add",
  opIdRischi_edit: "Rischi_edit",
  opIdRischi_delete: "Rischi_delete",
  // A2A EVOLUTIVE - Req. 2.1 - A. Stefanutti - FINE

  // --- ATTRIBUTI ---
  urlAttributi_list: "/attributi/lista",
  urlAttributi_add: "/attributi/aggiungi",
  urlAttributi_edit: "/attributi/modifica",
  urlAttributi_delete: "/attributi/elimina/",
  opIdAttributi_add: "Attributo_add",
  opIdAttributi_edit: "Attributo_edit",
  opIdAttributi_delete: "Attributo_delete",

  // --- TASKS ---
  urlTasks_list: "/tasks/lista",
  urlTasks_add: "/tasks/aggiungi",
  urlTasks_edit: "/tasks/modifica",
  urlTasks_delete: "/tasks/elimina/",
  opIdTasks_add: "Tasks_add",
  opIdTasks_edit: "Tasks_edit",
  opIdTasks_delete: "Tasks_delete",

  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_002, REQ_FUN_DPIATTR_003
  // REQ_TEC_DPIATTR_WEB_003, REQ_TEC_DPIATTR_WEB_004
  // Creare Amministrazione classi con wizard e associazione agli attributi.
  // --- CLASSI ---
  urlClassi_list: "/classi/lista",
  urlClassi_add: "/classi/aggiungi",
  urlClassi_edit: "/classi/modifica",
  urlClassi_delete: "/classi/elimina/",
  opIdClassi_add: "Classi_add",
  opIdClassi_edit: "Classi_edit",
  opIdClassi_delete: "Classi_delete",
  // --- ASSOCIAZIONE CLASSI-ATTRIBUTI ---
  urlClassiAttributi_list: "/classi/attributi/lista/u/",
  urlClassiAttributi_edit: "/classi/attributi/modifica",
  opIdClassiAttributi_edit: "ClassiAttributi_edit",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE

  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_008, REQ_FUN_DPIATTR_009, REQ_FUN_DPIATTR_010
  // REQ_TEC_DPIATTR_WEB_009, REQ_TEC_DPIATTR_WEB_010, REQ_TEC_DPIATTR_WEB_011
  // Creare Amministrazione modelli.
  // --- MODELLI ---
  urlModelli_list: "/modelli/lista",
  urlModelli_list_conallegati: "/modelli/conallegati/lista",
  urlModelli_add: "/modelli/aggiungi",
  urlModelli_edit: "/modelli/modifica",
  urlModelli_delete: "/modelli/elimina/",
  opIdModelli_add: "Modelli_add",
  opIdModelli_edit: "Modelli_edit",
  opIdModelli_delete: "Modelli_delete",
  // --- ASSOCIAZIONE MODELLI-ALLEGATI ---
  urlModelliAllegati_add: "/allegati/modelli/aggiungi",
  urlModelliAllegati_list: "/allegati/modelli/",
  urlModelliAllegati_delete: "/allegati/modelli/elimina",
  urlModelliAllegati_deletemany: "/allegati/modelli/elimina/multipli",
  opIdModelliAllegati_add: "AttachModelli_add",
  opIdModelliAllegati_delete: "AttachModelli_delete",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE

  // --- TIPI DI ATTREZZATURA ---
  urlTipiAttrezzatura_list: "/tipiattrezzatura/lista",
  urlTipiAttrezzatura_add: "/tipiattrezzatura/aggiungi",
  urlTipiAttrezzatura_edit: "/tipiattrezzatura/modifica",
  urlTipiAttrezzatura_delete: "/tipiattrezzatura/elimina/",
  opIdTipiAttrezzatura_add: "TipoAttrezzatura_add",
  opIdTipiAttrezzatura_edit: "TipoAttrezzatura_edit",
  opIdTipiAttrezzatura_delete: "TipoAttrezzatura_delete",
  // --- ASSOCIAZIONE TIPI DI ATTREZZATURA-ATTRIBUTI ---
  urlTipiAttrezzaturaAttributi_list: "/tipiattrezzatura/attributi/lista/u/",
  urlTipiAttrezzaturaAttributi_edit: "/tipiattrezzatura/attributi/modifica",
  opIdTipiAttrezzaturaAttributi_edit: "TipoAttrezzaturaAttributi_edit",

  // --- ATTREZZATURE ---
  urlAttrezzature_list: "/attrezzature/lista",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC)
  urlAttrezzature_list_usabili: "/attrezzature/lista/usabili",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC) - FINE
  urlAttrezzature_add: "/attrezzature/aggiungi",
  // A. Stefanutti - Approvvigionamento di attrezzature non "identificate" (trello https://trello.com/c/y9763xJ7).
  urlAttrezzature_stock: "/attrezzature/genera",
  // A. Stefanutti - Approvvigionamento di attrezzature non "identificate" - FINE
  // A. Stefanutti - Importazione massiva delle attrezzature tramite file CSV (trello https://trello.com/c/yyuJ5yk5)
  urlAttrezzature_import_csv: "/attrezzature/import/csv",
  // A. Stefanutti - Importazione massiva delle attrezzature tramite file CSV - FINE
  urlAttrezzature_edit: "/attrezzature/modifica",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC)
  urlAttrezzature_dismiss: "/attrezzature/dismiss",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC) - FINE
  urlAttrezzature_delete: "/attrezzature/elimina/",
  urlAttrezzatureZone_list: "/attrezzature/lista/zone/u/",
  urlAttrezzatureZone_edit: "/attrezzature/zone/modifica",
  // A. Stefanutti - POC OLIVETTI TIM REQ 8 Evidenziare attrezzature con dispositivo associato (trello https://trello.com/c/A6Cyp0Gi)
  urlAttrezzaturaDispositivi_list: "/attrezzature/listadispositivi/a/",
  // A. Stefanutti - POC OLIVETTI TIM REQ 8 Evidenziare attrezzature con dispositivo associato (trello https://trello.com/c/A6Cyp0Gi) - FINE
  opIdAttrezzature_add: "Attrezzatura_add",
  // A. Stefanutti - Approvvigionamento di attrezzature non "identificate" (trello https://trello.com/c/y9763xJ7).
  opIdAttrezzature_stock: "Attrezzatura_genera",
  // A. Stefanutti - Approvvigionamento di attrezzature non "identificate" - FINE
  opIdAttrezzature_edit: "Attrezzatura_edit",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC)
  opIdAttrezzature_dismiss: "Attrezzatura_dismiss",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC) - FINE
  opIdAttrezzature_delete: "Attrezzatura_delete",
  opIdAttrezzatureZone_edit: "AttrezzatureZone_edit",

  // --- ZONE ---
  urlZone_list: "/zone/lista",
  urlZone_add: "/zone/aggiungi",
  urlZone_edit: "/zone/modifica",
  urlZone_delete: "/zone/elimina/",
  opIdZone_add: "Zone_add",
  opIdZone_edit: "Zone_edit",
  opIdZone_delete: "Zone_delete",

  // --- LICENZA E PARAMETRI CONFIGURAZIONE ---
  urlStatusLicenza: "/sistema/licenza/status",
  urlUploadLicenza: "/sistema/licenza/carica",
  urlParametriConfigurazione_list: "/parametri/lista",
  urlParametriConfigurazione_add: "/parametri/aggiungi",
  urlParametriConfigurazione_edit: "/parametri/modifica",
  urlParametriConfigurazione_delete: "/parametri/elimina/",
  urlSync: "/sistema/sincronizza/",
  opIdUploadLicenza: "Licenza_Load",
  opIdParametriConfigurazione_add: "ParametriConfig_add",
  opIdParametriConfigurazione_edit: "ParametriConfig_edit",
  opIdParametriConfigurazione_delete: "ParametriConfig_del",
  
  // --- STABILIMENTI ---
  urlStabilimenti_list: "/stabilimenti/lista",
  urlStabilimenti_add: "/stabilimenti/aggiungi",
  urlStabilimenti_edit: "/stabilimenti/modifica",
  urlStabilimenti_delete: "/stabilimenti/elimina/",
  opIdStabilimenti_add: "Stabilimenti_add",
  opIdStabilimenti_edit: "Stabilimenti_edit",
  opIdStabilimenti_delete: "Stabilimenti_del",

  // --- UBICAZIONI ---
  urlUbicazioni_list: "/ubicazioni/lista",
  urlUbicazioni_add: "/ubicazioni/aggiungi",
  urlUbicazioni_edit: "/ubicazioni/modifica",
  urlUbicazioni_delete: "/ubicazioni/elimina/",
  opIdUbicazioni_add: "Ubicazioni_add",
  opIdUbicazioni_edit: "Ubicazioni_edit",
  opIdUbicazioni_delete: "Ubicazioni_delete",

  // --- AREACENTERS ---
  urlAreacenter_list: "/areacenter/lista",
  urlAreacenter_add: "/areacenter/aggiungi",
  urlAreacenter_edit: "/areacenter/modifica",
  urlAreacenter_delete: "/areacenter/elimina/",
  opIdAreacenter_add: "AreaCenter_add",
  opIdAreacenter_edit: "AreaCenter_edit",
  opIdAreacenter_delete: "AreaCenter_delete",

  // --- GESTIONE ORDINI ---
  urlOrdini_list: "/ordini/lista/recenti",
  urlOrdini_assegna: "/ordini/assegna",
  urlOrdini_accetta: "/ordini/accetta",
  urlOrdini_rifiuta: "/ordini/rifiuta",
  urlOrdini_storicoordine: "/ordini/storico/",
  urlOrdini_attrezzatureordine: "/attrezzature/presaincarico/lista/",
  urlOrdini_attrezzatureinterventiordine: "/attrezzature/presaincarico/coninterventi/lista/",
  opIdOrdini_assegna: "StatusOrdine_assigned",
  opIdOrdini_accetta: "StatusOrdine_accepted",
  opIdOrdini_rifiuta: "StatusOrdine_refused",

  // --- TIPI DI SCHEDA INTERVENTO ---
  urlTipiSchedaIntervento_list: "/tipischeda/lista",
  urlTipiSchedaIntervento_add: "/tipischeda/aggiungi",
  urlTipiSchedaIntervento_edit: "/tipischeda/modifica",
  urlTipiSchedaIntervento_delete: "/tipischeda/elimina/",
  opIdTipiSchedaIntervento_add: "TipoScheda_add",
  opIdTipiSchedaIntervento_edit: "TipoScheda_edit",
  opIdTipiSchedaIntervento_delete: "TipoScheda_delete",
  // --- ASSOCIAZIONE TIPI DI SCHEDA INTERVENTO-TASKS ---
  urlTipiSchedaInterventoTasks_list: "/tasks/tiposcheda/lista/u/",
  urlTipiSchedaInterventoTasks_edit: "/tasks/tiposcheda/modifica",
  opIdTipiSchedaInterventoTasks_edit: "TipoSchedaTasks_edit",

  // --- PERIODICITA' ---
  urlPeriodicita_list: "/periodicita/lista",
  urlPeriodicita_add: "/periodicita/aggiungi",
  urlPeriodicita_edit: "/periodicita/modifica",
  urlPeriodicita_delete: "/periodicita/elimina/",
  opIdPeriodicita_add: "Periodicita_add",
  opIdPeriodicita_edit: "Periodicita_edit",
  opIdPeriodicita_delete: "Periodicita_delete",

  // --- CLIENTI ---
  urlStabilimentiBySocieta_list: "/stabilimenti/lista/tutti/",
  urlAttrezzatureByStabilimento_list: "/attrezzature/lista/tutti/",

  // --- STATISTICHE ---
  urlStatisticheOrdiniByStatus: "/ordini/status",
  urlStatisticheOrdiniByInterventi: "/ordini/status/interventi",
  
  // --- CANALI NOTIFICA ---
  urlCanaliNotifica_list: "/canalinotifica/lista",
  urlCanaliNotifica_add: "/canalinotifica/aggiungi",
  urlCanaliNotifica_edit: "/canalinotifica/modifica",
  urlCanaliNotifica_delete: "/canalinotifica/elimina/",
  opIdCanaliNotifica_add: "CanaliNotifica_add",
  opIdCanaliNotifica_edit: "CanaliNotifica_edit",
  opIdCanaliNotifica_delete: "CanaliNotifica_delete",

  // --- LIVELLI NOTIFICA ---
  urlLivelliNotifica_list: "/livellinotifica/lista",
  urlLivelliNotifica_add: "/livellinotifica/aggiungi",
  urlLivelliNotifica_edit: "/livellinotifica/modifica",
  urlLivelliNotifica_delete: "/livellinotifica/elimina/",
  opIdLivelliNotifica_add: "LivelliNotifica_add",
  opIdLivelliNotifica_edit: "LivelliNotifica_edit",
  opIdLivelliNotifica_delete: "LivelliNotifica_delete",

  // --- TIPI DI OPERAZIONE ---
  urlTipiOperazione_list: "/tipioperazione/lista",
  urlTipiOperazione_list_nosysadmin: "/tipioperazione/nosysadmin/lista",
  urlTipiOperazione_add: "/tipioperazione/aggiungi",
  urlTipiOperazione_edit: "/tipioperazione/modifica",
  urlTipiOperazione_delete: "/tipioperazione/elimina/",
  opIdTipiOperazione_add: "ActionKind_add",
  opIdTipiOperazione_edit: "ActionKind_edit",
  opIdTipiOperazione_delete: "ActionKind_delete",
  // --- ASSOCIAZIONE TIPI DI OPERAZIONE-CANALI DI NOTIFICA
  urlTipiOperazioneCanaliNotifica_list: "/tipioperazione/lista/canalinotifica/u/",
  urlTipiOperazioneCanaliNotifica_edit: "/tipioperazione/canalinotifica/modifica",
  opIdTipiOperazioneCanaliNotifica_edit: "TipiOperazCanaliNotifica_edit",

  // --- TIPI DI CAMPO ---
  urlTipiCampo_list: "/tipicampo/lista",
  urlTipiCampo_add: "/tipicampo/aggiungi",
  urlTipiCampo_edit: "/tipicampo/modifica",
  urlTipiCampo_delete: "/tipicampo/elimina/",
  opIdTipiCampo_add: "TipiCampo_add",
  opIdTipiCampo_edit: "TipiCampo_edit",
  opIdTipiCampo_delete: "TipiCampo_delete",

  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_001
  // REQ_TEC_DPIATTR_WEB_001
  // Modificare Amministrazione tipi campo (dato) - lasciare SOLO vista tabellare
  // - Rinominato tutto in "Dato" invece di "Campo"
  // --- TIPI DI DATO ---
  urlTipiDato_list: "/tipidato/lista",
  urlTipiDato_add: "/tipidato/aggiungi",
  urlTipiDato_edit: "/tipidato/modifica",
  urlTipiDato_delete: "/tipidato/elimina/",
  opIdTipiDato_add: "TipiDato_add",
  opIdTipiDato_edit: "TipiDato_edit",
  opIdTipiDato_delete: "TipiDato_delete",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE

  // --- TIPI DI ATTIVITA' ---
  urlTipiAttivita_list: "/tipiattivita/lista",
  urlTipiAttivita_add: "/tipiattivita/aggiungi",
  urlTipiAttivita_edit: "/tipiattivita/modifica",
  urlTipiAttivita_delete: "/tipiattivita/elimina/",
  opIdTipiAttivita_add: "TipiAttivita_add",
  opIdTipiAttivita_edit: "TipiAttivita_edit",
  opIdTipiAttivita_delete: "TipiAttivita_delete",

  // --- FBI SOCIETA ---
  urlDatiSocietari: "/clienti/datisocietari/",

  // --- REGISTRO DEI CONTROLLI ---
  urlRegistroControlli: "/stabilimenti/registrocontrolli/",

  // --- SCHEDA INTERVENTO ---
  urlSchedaIntervento: "/schede/intervento/",

  // --- TIPI DI ALLARME ---
  urlTipiAllarme_list: "/tipiallarme/lista",
  urlTipiAllarme_add: "/tipiallarme/aggiungi",
  urlTipiAllarme_edit: "/tipiallarme/modifica",
  urlTipiAllarme_delete: "/tipiallarme/elimina/",
  opIdTipiAllarme_add: "TipiAllarme_add",
  opIdTipiAllarme_edit: "TipiAllarme_edit",
  opIdTipiAllarme_delete: "TipiAllarme_delete",

  // --- SOLUZIONI ALLARME ---
  urlSoluzioniAllarme_list: "/soluzioni/lista",
  urlSoluzioniAllarme_add: "/soluzioni/aggiungi",
  urlSoluzioniAllarme_edit: "/soluzioni/modifica",
  urlSoluzioniAllarme_delete: "/soluzioni/elimina/",
  opIdSoluzioniAllarme_add: "Soluzioni_add",
  opIdSoluzioniAllarme_edit: "Soluzioni_edit",
  opIdSoluzioniAllarme_delete: "Soluzioni_delete",

  // --- LIVELLI LOG ---
  urlLivelliLog_list: "/livellilog/lista",
  urlLivelliLog_add: "/livellilog/aggiungi",
  urlLivelliLog_edit: "/livellilog/modifica",
  urlLivelliLog_delete: "/livellilog/elimina/",
  opIdLivelliLog_add: "LogLevel_add",
  opIdLivelliLog_edit: "LogLevel_edit",
  opIdLivelliLog_delete: "LogLevel_delete",

  // --- PROTOCOLLI ---
  urlProtocolli_list: "/protocolli/lista",
  urlProtocolli_add: "/protocolli/aggiungi",
  urlProtocolli_edit: "/protocolli/modifica",
  urlProtocolli_delete: "/protocolli/elimina/",
  opIdProtocolli_add: "Protocol_add",
  opIdProtocolli_edit: "Protocol_edit",
  opIdProtocolli_delete: "Protocol_delete",

  // --- TECNOLOGIE ---
  urlTecnologie_list: "/tecnologie/lista",
  urlTecnologie_add: "/tecnologie/aggiungi",
  urlTecnologie_edit: "/tecnologie/modifica",
  urlTecnologie_delete: "/tecnologie/elimina/",
  opIdTecnologie_add: "Technology_add",
  opIdTecnologie_edit: "Technology_edit",
  opIdTecnologie_delete: "Technology_delete",

  // --- TIPI DI ASSETTO
  urlTipiAssetto_list: "/tipiassetto/lista",
  urlTipiAssetto_add: "/tipiassetto/aggiungi",
  urlTipiAssetto_edit: "/tipiassetto/modifica",
  urlTipiAssetto_delete: "/tipiassetto/elimina/",
  opIdTipiAssetto_add: "PpeLayoutKind_add",
  opIdTipiAssetto_edit: "PpeLayoutKind_edit",
  opIdTipiAssetto_delete: "PpeLayoutKind_delete",

  // --- TIPI DI LAVORO ---
  urlTipiLavoro_list: "/tipilavoro/lista",
  urlTipiLavoro_add: "/tipilavoro/aggiungi",
  urlTipiLavoro_edit: "/tipilavoro/modifica",
  urlTipiLavoro_delete: "/tipilavoro/elimina/",
  opIdTipiLavoro_add: "JobKind_add",
  opIdTipiLavoro_edit: "JobKind_edit",
  opIdTipiLavoro_delete: "JobKind_delete",

  // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9)
  // --- TIPI DI DPI ---
  urlTipiControllo_list: "/tipicontrollo/lista",

  // --- TIPI DI ANOMALIA ---
  urlTipiAnomalia_list: "/tipianomalia/lista",
  urlTipiAnomalia_add: "/tipianomalia/aggiungi",
  urlTipiAnomalia_edit: "/tipianomalia/modifica",
  urlTipiAnomalia_delete: "/tipianomalia/elimina/",
  opIdTipiAnomalia_add: "TipoAnomalia_add",
  opIdTipiAnomalia_edit: "TipoAnomalia_edit",
  opIdTipiAnomalia_delete: "TipoAnomalia_delete",

  // --- MOTIVAZIONI ---
  urlMotivazioni_list: "/motivazioni/lista",
  urlMotivazioni_add: "/motivazioni/aggiungi",
  urlMotivazioni_edit: "/motivazioni/modifica",
  urlMotivazioni_delete: "/motivazioni/elimina/",
  opIdMotivazioni_add: "Motivazioni_add",
  opIdMotivazioni_edit: "Motivazioni_edit",
  opIdMotivazioni_delete: "Motivazioni_delete",
  // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9) - FINE

  // --- DPI ---
  urlDpi_list: "/dpi/lista/tutti",
  urlDpi_free_list: "/dpi/lista/tutti/liberi",
  // A. Stefanutti - 26/04/2021 - Filtro DPI liberi per idoneità
  urlDpi_free_idoneita_list: "/dpi/lista/peridoneita/liberi",
  // A. Stefanutti - 26/04/2021 - Filtro DPI liberi per idoneità - FINE
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC)
  urlDpi_free_idoneita_list_nonscad_nondism: "/dpi/lista/peridoneita/liberi/usabili",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC) - FINE
  opIdDpi_free_list: "Ppe_free_list",

  // --- ASSETTI ---
  urlAssetti_list: "/assetti/lista",
  urlAssetti_add: "/assetti/aggiungi",
  urlAssetti_edit: "/assetti/modifica",
  urlAssetti_delete: "/assetti/elimina/",
  urlAssettiTipiDpi_list: "/assetti/lista/tipidpi/a/",
  urlAssettiTipiDpi_edit: "/assetti/tipidpi/modifica",
  opIdAssetti_add: "PpeLayout_add",
  opIdAssetti_edit: "PpeLayout_edit",
  opIdAssetti_delete: "PpeLayout_delete",
  opIdAssettiTipiDpi_edit: "PpeLayoutPpeKinds_edit",

  // --- TIPI DI DPI ---
  urlTipiDpi_list: "/tipidpi/lista",

  // --- TIPI DI TELEMETRIA ---
  urlTipiTelemetria_list: "/tipitelemetria/lista",
  urlTipiTelemetria_add: "/tipitelemetria/aggiungi",
  urlTipiTelemetria_edit: "/tipitelemetria/modifica",
  urlTipiTelemetria_delete: "/tipitelemetria/elimina/",
  opIdTipiTelemetria_add: "TipiTelemetria_add",
  opIdTipiTelemetria_edit: "TipiTelemetria_edit",
  opIdTipiTelemetria_delete: "TipiTelemetria_delete",

  // --- TIPI DI DISPOSITIVO ---
  urlTipiDispositivo_list: "/tipidispositivo/lista",
  urlTipiDispositivo_add: "/tipidispositivo/aggiungi",
  urlTipiDispositivo_edit: "/tipidispositivo/modifica",
  urlTipiDispositivo_delete: "/tipidispositivo/elimina/",
  urlTipiDispositivoTipiTelemetria_list: "/tipidispositivo/tipitelemetria/lista/tutti/",
  urlTipiDispositivoTipiTelemetria_edit: "/tipidispositivo/tipitelemetria/modifica",
  opIdTipiDispositivo_add: "DeviceKind_add",
  opIdTipiDispositivo_edit: "DeviceKind_edit",
  opIdTipiDispositivo_delete: "DeviceKind_delete",
  opIdTipiDispositivoTipiTelemetria_edit: "TipoDispTipiTelemetria_edit",

  // --- DISPOSITIVI ---
  // urlDevicesScanFilteredList: "/dispositivi/scan/lista/filtri", // Dati per popolare il form di filtraggio del ciclo di vita
  // opIdDevicesScanFilteredList: "DevicesScanFilteredList",
  // urlDevicesScanFilteredList_nosysadmin: "/dispositivi/scan/nosysadmin/lista/filtri/s/", // Dati per popolare il form di filtraggio del ciclo di vita, filtrati per società
  // opIdDevicesScanFilteredList_nosysadmin: "DevicesScanFilteredList_nosys",
  // urlDevicesScanAnomaliesFilteredList_nosysadmin: "/dispositivi/scan/nonrilevati/nosysadmin/lista/filtri/s/", // Dati per popolare il form di filtraggio delle anomalie del ciclo di vita, filtrati per società
  // opIdDevicesScanAnomaliesFilteredList_nosysadmin: "DevicesScanAnomFiltList_nosys",
  // urlDevicesScanAnomaliesFilteredList: "/dispositivi/scan/nonrilevati/lista/filtri", // Dati per popolare il form di filtraggio delle anomalie del ciclo di vita
  // opIdDevicesScanAnomaliesFilteredList: "DevicesScanAnomFiltList",
  // urlDevicesScanAnalisys: "/dispositivi/scan/analisi", // Analisi del ciclo di vita (rilevati/non rilevati) filtrato mediante form
  // opIdDevicesScanAnalisys: "DevicesScanAnalisys",
  // urlDevicesScanSearch: "/dispositivi/scan/cerca", // Elenco registrazioni del ciclo di vita filtrato mediante form
  // opIdDevicesScanSearch: "DevicesScanSearch",
  urlDispositivi_list: "/dispositivi/lista/tutti",
  urlDispositivi_list_filtered: "/dispositivi/lista/tutti/filtrata",
  urlDispositivi_add: "/dispositivi/aggiungi",
  urlDispositivi_add_streamsets: "",
  // A. Stefanutti - Importazione massiva dei dispositivi tramite file CSV (trello https://trello.com/c/UQ06QJ6S)
  urlDispositivi_import_csv: "/dispositivi/import/csv",
  // A. Stefanutti - Importazione massiva dei dispositivi tramite file CSV (trello https://trello.com/c/UQ06QJ6S) - FINE
  urlDispositivi_edit: "/dispositivi/modifica",
  urlDispositivi_verifydelete: "/dispositivi/verificauso/",
  urlDispositivi_delete: "/dispositivi/elimina/",
  urlDispositiviDpi_list: "/dispositivi/lista/dpi/d/",
  urlDispositiviDpi_edit: "/dispositivi/dpi/modifica",
  urlDispositiviUtenti_list: "/dispositivi/lista/utenti/d/",
  urlDispositiviUtenti_edit: "/dispositivi/utenti/modifica",
  urlDispositiviMezzi_list: "/dispositivi/lista/mezzi/d/",
  urlDispositiviMezzi_edit: "/dispositivi/mezzi/modifica",
  urlDispositiviAttrezzature_list: "/dispositivi/lista/attrezzature/d/",
  urlDispositiviAttrezzature_edit: "/dispositivi/attrezzature/modifica",
  urlDispositiviDispositivi_list: "/dispositivi/lista/dispositivi/d/",
  urlDispositiviDispositivi_edit: "/dispositivi/dispositivi/modifica",
  opIdDispositivi_add: "Device_add",
  opIdDispositivi_add_streamsets: "Device_add_streamsets",
  opIdDispositivi_edit: "Device_edit",
  opIdDispositivi_delete: "Device_delete",
  opIdDispositiviDpi_edit: "DevicePpes_edit",
  opIdDispositiviUtenti_edit: "DeviceUsers_edit",
  opIdDispositiviAttrezzature_edit: "DispositiviAttrezzature_edit",
  opIdDispositiviDispositivi_edit: "DispositiviDispositivi_edit",
  opIdDispositiviMezzi_edit: "DispositiviMezzi_edit",

  // --- TIPI DI MEZZO
  urlTipiMezzo_list: "/tipimezzo/lista",
  urlTipiMezzo_add: "/tipimezzo/aggiungi",
  urlTipiMezzo_edit: "/tipimezzo/modifica",
  urlTipiMezzo_delete: "/tipimezzo/elimina/",
  opIdTipiMezzo_add: "Tipimezzo_add",
  opIdTipiMezzo_edit: "Tipimezzo_edit",
  opIdTipiMezzo_delete: "Tipimezzo_delete",

  // --- MEZZI
  urlMezzi_list: "/mezzi/lista",
  urlMezzi_add: "/mezzi/aggiungi",
  urlMezzi_edit: "/mezzi/modifica",
  urlMezzi_delete: "/mezzi/elimina/",
  urlMezziZone_list: "/mezzi/lista/zone/u/",
  urlMezziZone_edit: "/mezzi/zone/modifica",
  opIdMezzi_add: "Mezzo_add",
  opIdMezzi_edit: "Mezzo_edit",
  opIdMezzi_delete: "Mezzo_delete",
  opIdMezziZone_edit: "MezziZone_edit",

  // --- OPERATIVITA' - DASHBOARD ---
  urlKpiLavoriZone: "/analisi/kpi/numerolavorieseguitiZone",
  urlKpiLavoriZone_ultimi: "/analisi/kpi/numerolavoriultimigiorniZone",
  urlKpiLavoriConAnomalieZone: "/analisi/kpi/numerolavoriconanomalieZone",
  urlKpiLavoriConAnomalieZone_ultimi: "/analisi/kpi/numerolavoriconanomalieultimigiorniZone",
  urlKpiOperatoriZone: "/analisi/kpi/numerooperatorilavoesegZone",
  urlKpiOperatoriZone_ultimi: "/analisi/kpi/numerolavoratoridistultimigiorniZone",
  urlKpiCheckQuotidianiZone: "/analisi/kpi/numerocheckquotidianiZone",
  urlKpiCheckQuotidianiZone_ultimi: "/analisi/kpi/numerocheckquotidianingiorniZone",
  urlGraphLavoriMonoMultiZone: "/analisi/lavoriperoperatmultiopZone",
  urlGraphLavoriPerZonaZone: "/analisi/lavoriperzonaZone",
  urlGraphLavoriPerAttivitaZone: "/analisi/lavoriperattivitaZone",
  urlGraphLavoriPerTipoAssettoZone: "/analisi/lavoripertipoassettoZone",

  // DEMO_SAIPEM_REQ_04 - A. Stefanutti
  // Dashboard per situazione consegne DPI.
  // --- OPERATIVITA' - DASHBOARD CONSEGNA DPI ---
  urlKpiDpiAssegnatiZone: "/analisi/kpi/totaledpiassegnatiZone",
  urlKpiDpiAssegnatiZone_ultimi: "/analisi/kpi/numerolavoriultimigiorni", // TODO
  urlKpiDpiDaAssegnareZone: "/analisi/kpi/totaledpidaassegnareZone",
  urlKpiDpiDaAssegnareZone_ultimi: "/analisi/kpi/numerolavoratoridistultimigiorni", // TODO
  urlKpiDpiScadutiAssegnatiZone: "/analisi/kpi/totaledpiscadutiassegnatiZone",
  urlKpiDpiScadutiAssegnatiZone_ultimi: "/analisi/kpi/numerolavoriconanomalieultimigiorni", // TODO
  urlKpiDpiInScadenzaAssegnatiZone: "/analisi/kpi/totaledpiinscadenzaassegnatiZone",
  urlKpiDpiInScadenzaAssegnatiZone_ultimi: "/analisi/kpi/numerocheckquotidianingiorni", // TODO
  urlGraphDpiDaAssegnarePerTipoZone: "/analisi/dpidisponibilipertipoZone",
  urlGraphDpiAssegnatiPerZonaZone: "/analisi/dpiassegnatiperzonaZone",
  // DEMO_SAIPEM_REQ_04 - A. Stefanutti - FINE

  // --- OPERATIVITA' - RISULTATI CONTROLLI ---
  urlControlli_filters: "/controlli/lista/filtri", // Dati per popolare il form di filtraggio dei controlli
  urlControlli_search: "/controlli/cerca", // Deve rimanere, in quanto usata per recuperare il dettaglio di un singolo controllo
  urlControlliZone_search: "/controlli/cercaZone",
  opIdControlliDpiCompletati: "Controlli_Dpi_Completati",
  urlGraphCQMotivazioniNonRilevatiZone: "/analisi/checkquotidianimotivazioniZone",
  urlGraphCQPerEsitoZone: "/analisi/checkquotidianiperesitoZone",
  urlGraphCQPerGiornoZone: "/analisi/checkquotidianipergiornoZone",
  urlGraphCQTagKOPerTipoDpiZone: "/analisi/checkquotidianitagnonrildotZone",
  urlGraphCMMotivazioniNonRilevatiZone: "/analisi/checkmultiopmotivazionitagnonrilZone",
  urlGraphCMTagKOPerTipoDpiZone: "/analisi/checkmultiopdpirichiestinonrildotZone",
  
  // --- OPERATIVITA' - LAVORI ---
  urlGraphLavoriMonoPerAttivitaZone: "/analisi/lavorisingopperattivitaZone",
  urlGraphLavoriMonoPerTipoAssettoZone: "/analisi/lavorisingoppertipoassettoZone",
  urlGraphLavoriMonoConSenzaAllarmiZone: "/analisi/lavorisingopconsenzaallarmiZone",
  urlGraphLavoriMonoAllarmiPerTipoDpiESoluzioneZone: "/analisi/allarmisingoprientratipersoluzioneZone",
  urlGraphLavoriMultiPerAttivitaZone: "/analisi/lavorimultiopperattivitaZone",
  urlGraphLavoriMultiPerTipoAssettoZone: "/analisi/lavorimultioppertipoassettoZone",
  urlGraphLavoriMultiPerNumeroOperatoriZone: "/analisi/lavorimultiopsquadreZone",

  // OPERATIVITA' - REPORTS ANALITICI
  urlLavoriEseguiti_filters: "/lavorieseguiti/lista/filtri", // Dati per popolare il form di filtraggio degli alert
  urlLavoriEseguitiZone_search: "/lavorieseguiti/cercaZone",
  urlAllarmi_filters: "/allarmi/lista/filtri", // Dati per popolare il form di filtraggio degli alert
  urlAllarmiZone_search: "/allarmi/cercaZone",
  // DEMO_SAIPEM_REQ_01 - A. Stefanutti
  // Verbale consegna DPI.
  // A. Stefanutti - Filtro zone e form ricerca in Verbali consegna e storico assegnazioni (trello https://trello.com/c/GX3i5GoX)
  urlVerbaliConsegna_filters: "/verbaliconsegna/lista/filtri", // Dati per popolare il form di filtraggio degli alert
  urlVerbaliConsegnaZone_search: "/verbaliconsegna/cercaZone",
  // A. Stefanutti - Filtro zone e form ricerca in Verbali consegna e storico assegnazioni - FINE
  // DEMO_SAIPEM_REQ_01 - A. Stefanutti - FINE
  urlBatteriaZone_search: "/analisi/report/dpi/livellibatteriazone",
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti
  // Report utenti che non hanno fatto il check quotidiano (senza interazione con schedulatore A2A).
  // A. Stefanutti - Report check quotidiani non eseguiti (https://trello.com/c/kvxNJYfr)
  // urlOperatoriSenzaCQ_search: "/utenti/cerca/senzacq",
  urlOperatoriSenzaCQ_search: "/analisi/report/checkquotidianononeseguito",
  // A. Stefanutti - Nuovo report utenti che hanno ricevuto alert mancato check (https://trello.com/c/EehLb15k)
  // A. Stefanutti - Report check quotidiani non eseguiti (https://trello.com/c/kvxNJYfr) - FINE
  urlOperatoriConAlertCheck_search: "/analisi/report/utenticonalertmancatocheck",
  // A. Stefanutti - Nuovo report utenti che hanno ricevuto alert mancato check (https://trello.com/c/EehLb15k) - FINE
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti - FINE
  urlOperatoriDotazioneIncompletaZone_search: "/analisi/report/dotazionidpiincompletezone",
  // A. Stefanutti - Nuovo report operatori senza check multioperatore (https://trello.com/c/EJ3Yc6LB)
  urlOperatoriConAlertCheckMulti_search: "/analisi/report/utenticonalertmancatocheckcisec",
  // A. Stefanutti - Nuovo report operatori senza check multioperatore (https://trello.com/c/EJ3Yc6LB) - FINE

  // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9)
  // OPERATIVITA' - REPORTS AGGREGATI
  urlCheckQuotAssettoEsito: "/analisi/reportistica/aggregata/report/a/cqcorrassesitocheck",
  urlCheckQuotAnomAssettoGiustif: "/analisi/reportistica/aggregata/report/b/anomcqcorrassgiustins",
  urlCheckQuotAnomAssettoTipiDpi: "/analisi/reportistica/aggregata/report/c/anomcqcorrasstipodpi",
  urlCheckMultiAssettoComponentiEsitoKPI: "/analisi/reportistica/aggregata/report/d1/riepilogocheckmo",
  urlCheckMultiAssettoComponentiEsito: "/analisi/reportistica/aggregata/report/d2/checkmoeseguiti",
  urlCheckMultiAnomAssettoGiustif: "/analisi/reportistica/aggregata/report/e/anomaliecheckmoassettogiustif",
  urlCheckMultiAnomAssettoTipiDpi: "/analisi/reportistica/aggregata/report/f/anomaliecheckmoassettotipodpi",
  urlCheckMonoAssettoEsitoKPI: "/analisi/reportistica/aggregata/report/g1/riepilogocheckmo",
  urlCheckMonoAssettoEsito: "/analisi/reportistica/aggregata/report/g2/checkmoeseguiti",
  urlCheckMonoAnomAssettoSoluz: "/analisi/reportistica/aggregata/report/h/anomaliecheckmoasserisol",
  urlCheckMonoAnomAssettoTipiDpi: "/analisi/reportistica/aggregata/report/i/anomaliecheckmoassetipodpi",
  // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9) - FINE

  // --- OPERATIVITA' - DPI ---
  urlTableSituazioneDpiZone: "/analisi/report/dpiutentezone",
  // A. Stefanutti - POC OLIVETTI TIM Req 4 Vista attrezzature non assegnate (con dispositivi) (trello https://trello.com/c/hw2r5lae)
  urlTableSituazioneDpiNonAssegnatiZone: "/analisi/report/dpinonassociati",
  // A. Stefanutti - POC OLIVETTI TIM Req 4 Vista attrezzature non assegnate (con dispositivi) (trello https://trello.com/c/hw2r5lae) - FINE

  // --- ATTIVITA ---
  urlAttivita_list: "/attivita/lista",
  urlAttivita_add: "/attivita/aggiungi",
  urlAttivita_edit: "/attivita/modifica",
  urlAttivita_delete: "/attivita/elimina/",
  urlAttivitaCompetenze_list: "/attivita/competenze/lista/u/",
  urlAttivitaCompetenze_edit: "/attivita/competenze/modifica",
  opIdAttivita_add: "Attivita_add",
  opIdAttivita_edit: "Attivita_edit",
  opIdAttivita_delete: "Attivita_delete",
  opIdAttivitaCompetenze_edit: "AttivitaCompetenze_edit",

  // --- LAVORI ---
  urlLavori_list: "/lavori/lista",
  urlLavori_add: "/lavori/aggiungi",
  urlLavori_edit: "/lavori/modifica",
  urlLavori_delete: "/lavori/elimina/",
  urlLavoriAttivita_list: "/lavori/attivita/lista/l/",
  urlLavoriAttivita_edit: "/lavori/attivita/modifica",
  urlLavoriAree_list: "/lavori/lista/aree/l/",
  urlLavoriAree_edit: "/lavori/aree/modifica",
  opIdLavori_add: "Job_add",
  opIdLavori_edit: "Job_edit",
  opIdLavori_delete: "Job_delete",
  opIdLavoriAttivita_edit: "JobAttivita_edit",
  
  // --- ALLEGATI ---
  urlAllegatiSessioni_download: "/allegati/sessioni/scarica",
  // DEMO_SAIPEM_REQ_01 - A. Stefanutti
  // Verbale consegna DPI.
  urlAllegatiVerbaliConsegna_download: "/allegati/verbaliconsegna/scarica",
  // DEMO_SAIPEM_REQ_01 - A. Stefanutti - FINE
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_008, REQ_FUN_DPIATTR_009, REQ_FUN_DPIATTR_010
  // REQ_TEC_DPIATTR_WEB_009, REQ_TEC_DPIATTR_WEB_010, REQ_TEC_DPIATTR_WEB_011
  // Creare Amministrazione modelli.
  urlAllegatiModelli_download: "/allegati/modelli/scarica",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE

  // --- REALTIME
  urlSituazioneRT: "/rt/situazione",

  // --- TELEMETRIA
  urlTelemetryEntities_list: "/telemetria/dati/entita/lista",
  urlDeviceTelemetryData: "/telemetria/dati/dispositivo",
  urlAzioniAttrezzature: "/telemetria/attrezzature/azioni",

  // --- CANTIERI TODO
  // urlYard_list: "/cantieri/lista",
  urlYardRecents_list: "/cantieri/lista/recenti",
  // urlYard_add: "/cantieri/aggiungi",
  // urlYard_edit: "/cantieri/modifica",
  // urlYard_delete: "/cantieri/elimina/",
  // opIdYard_add: "Cantieri_add",
  // opIdYard_edit: "Cantieri_edit",
  // opIdYard_delete: "Cantieri_delete",

  // A. Stefanutti - Cancellazione dati obsoleti (trello https://trello.com/c/hqYb423c)
  // --- CANCELLAZIONE DATI OBSOLETI ---
  urlDataDeleteStop: "/sistema/dynamicsched/0",
  urlDataDeleteStart: "/sistema/dynamicsched/1",
  urlDataDeleteStatus: "/sistema/dynamicsched/status",
  // A. Stefanutti - Cancellazione dati obsoleti (trello https://trello.com/c/hqYb423c) - FINE
  // A. Stefanutti - Restart servizio invio CID a Tibco da Web UI - (https://trello.com/c/NZ09YMq2)
  urlSFM100Login: "/servizi/accesso",
  urlSFM100Stop: "/sistema/clientrichodl/action/0",
  urlSFM100Start: "/sistema/clientrichodl/action/1",
  urlSFM100Status: "/sistema/clientrichodl/status",
  // A. Stefanutti - Restart servizio invio CID a Tibco da Web UI - (https://trello.com/c/NZ09YMq2) - FINE

  // --- BLACK BOX ---
  urlBBFilterList: "/blackbox/lista/filtri", // Dati per popolare il form di filtraggio dei log
  urlBBSearch: "/blackbox/cerca",
  urlBBTimeline: "/blackbox/timeline",

  // --- LOG ---
  urlLogTimeline: "/log/timeline",

  // --- TIPI DI PARAMETRO DI CONFIGURAZIONE ---
  urlTipiParametroConfigurazione_list: "/tipiparametroconfig/lista",
  urlTipiParametroConfigurazione_add: "/tipiparametroconfig/aggiungi",
  urlTipiParametroConfigurazione_edit: "/tipiparametroconfig/modifica",
  urlTipiParametroConfigurazione_delete: "/tipiparametroconfig/elimina/",
  opIdTipiParametroConfigurazione_add: "TipiParametroConfig_add",
  opIdTipiParametroConfigurazione_edit: "TipiParametroConfig_edit",
  opIdTipiParametroConfigurazione_delete: "TipiParametroConfig_delete",

  // --- REGISTRO ASSEGNAZIONI ---
  urlRegistroAssegnazioniUtentiDpi_filters: "/registroasseg/lista/filtri",
  urlRegistroAssegnazioniUtentiDpi_search: "/registroasseg/cerca/utenti/dpi",
  // A. Stefanutti - Filtro zone e form ricerca in Verbali consegna e storico assegnazioni (trello https://trello.com/c/GX3i5GoX)
  urlRegistroAssegnazioniUtentiDpiZone_search: "/registroasseg/cercaZone/utenti/dpi",
  // A. Stefanutti - Filtro zone e form ricerca in Verbali consegna e storico assegnazioni - FINE
  // A. Stefanutti - Errore storico assegnazioni da scheda utente (trello https://trello.com/c/IYeNFPg9)
  urlAssegnazioniPerUtente: "/registroasseg/cerca/perutente",
  // A. Stefanutti - Errore storico assegnazioni da scheda utente (trello https://trello.com/c/IYeNFPg9) - FINE



  // ******************************************************************
  // Altri valori
  // ******************************************************************

  CODICESORGENTELOG_WEB: "WEB",
  CODICESORGENTELOG_MOBILE_DM: "DMMOBILE",
  CODICESORGENTELOG_MOBILE_MM: "MMMOBILE",
  CODICESORGENTELOG_REST: "REST",
  CODICESORGENTELOG_EDGE_SAW: "SAWEDGE",
  CODICESORGENTELOG_MOBILE_SAW: "SAWMOBILE",
  CODICESORGENTELOG_MOBILE_UAT: "UATMOBILE",
  
  OP_KIND_CODE_INIZIOLAVORO: "INIZIOLAVORO",
  OP_KIND_CODE_FINELAVORO: "FINELAVORO",
  // A. Stefanutti - Cambio attività a caldo (trello https://trello.com/c/1JwRbc2R)
  OP_KIND_CODE_CAMBIOATTIVITA: "CHGTASK",
  // A. Stefanutti - Cambio attività a caldo - FINE
  OP_KIND_CODE_QUEST: "QUEST",
  OP_KIND_CODE_RAVICA: "RAVI",
  
  IMPORT_FILE_KIND_JSON: 1,
  IMPORT_FILE_KIND_CSV: 2,
  IMPORT_FILE_KIND_DAT: 3,
  
  CSV_DEFAULT_SEPARATOR: ";",

  PERIOD_TUTTI: "tutti",
  // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9)
  PERIOD_ULTIMOTRIMESTRE: "ultimotrimestre",
  // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9) - FINE
  PERIOD_ULTIMOMESE: "ultimomese",
  PERIOD_ULTIMASETTIMANA: "ultimasett",
  PERIOD_OGGI: "oggi",
  PERIOD_CUSTOM: "custom",

  REALTIME_MAP_ZOOM_LEVEL_DETAIL: 18,

  YARD_STATUS_CODE_INIZ: "INIZ",
  YARD_STATUS_CODE_APER: "APER",
  YARD_STATUS_CODE_SOSP: "SOSP",
  YARD_STATUS_CODE_SOSF: "SOSF",
  YARD_STATUS_CODE_CHIU: "CHIU",
  YARD_STATUS_CODE_TERM: "TERM",

  YARD_SUBSTATUS_CODE_INIZ: "INIZ",
  YARD_SUBSTATUS_CODE_ACON: "ACON",
  YARD_SUBSTATUS_CODE_CONS: "CONS",
  YARD_SUBSTATUS_CODE_ARES: "ARES",
  YARD_SUBSTATUS_CODE_REST: "REST",
  YARD_SUBSTATUS_CODE_TERM: "TERM",

  NOTIFICATIONS_REFRESH_INTERVAL: 15,
  NOTIFICATIONS_LEVEL_CODE_DEBUG: "DEBUG",
  NOTIFICATIONS_LEVEL_CODE_ERROR: "ERROR",
  NOTIFICATIONS_LEVEL_CODE_FATAL: "FATAL",
  NOTIFICATIONS_LEVEL_CODE_INFO: "INFO",
  NOTIFICATIONS_LEVEL_CODE_TRACE: "TRACE",
  NOTIFICATIONS_LEVEL_CODE_WARNING: "WARN",
  NOTIFICATIONS_CHANNEL_CODE_WEB: "WEB",
  NOTIFICATIONS_CHANNEL_CODE_EMAIL: "EMAIL",
  NOTIFICATIONS_CHANNEL_CODE_SMS: "SMS",

  MODULE_CODE_SYS: "SYS",
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq)
  MODULE_CODE_BB: "BB",
  MODULE_CODE_COS: "COS",
  MODULE_CODE_COSRT: "COSRT",
  MODULE_CODE_LDC: "LDC",
  MODULE_CODE_MM: "MM",
  MODULE_CODE_SAW: "SAW",
  MODULE_CODE_SAWRT: "SAWRT",
  MODULE_CODE_SSIGN: "SSIGN",
  MODULE_CODE_SSIGNRT: "SSIGNRT",
  MODULE_CODE_UAT: "UAT",
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq) - FINE

  PICTURE_COMPRESSION_MAXSIZE: 1, // the max size in MB, defaults to 2MB
  PICTURE_COMPRESSION_QUALITY: 1, // the quality of the image, max is 1
  PICTURE_COMPRESSION_MAXWIDTH: 200, // the max width of the output image, defaults to 1920px
  PICTURE_COMPRESSION_MAXHEIGHT: 240, // the max height of the output image, defaults to 1920px

  TELEMETRY_OBSOLESCENCE_CHECK_INTERVAL: 1, // minuti, intervallo di controllo dell'obsolescenza dei dati di telemetria di ogni dispositivo
  TELEMETRY_OBSOLESCENCE: 1, // minuti, tempo dopo il quale i dati di telemetria di un dispositivo sono dichiarati obsoleti
  TELEMETRYKIND_CODE_GEO: "GEO",
  TELEMETRYKIND_CODE_BATT: "BATT",
  TELEMETRYKIND_CODE_CHIPTEMP: "CHIPTEMP",
  TELEMETRYKIND_CODE_SSTELEM: "SSTELEM",
  SSIGN_DEVICE_NIBBLE_STOP: "0000", // cartello fermo
  SSIGN_DEVICE_NIBBLE_START: "0001", // cartello in partenza
  SSIGN_DEVICE_NIBBLE_FORCE_GPS_ON: "0100", // richiesta al GPS di aggiornare le coordinate 
  SSIGN_DEVICE_NIBBLE_CALIBRATE_IMU: "0101", // richiesta calibrazione IMU (non era presente nella GUI) 
  SSIGN_DEVICE_NIBBLE_AQUIRING: "0010", // cartello in acquisizione
  SSIGN_DEVICE_NIBBLE_STATUS: "0011", // è stato richiesto uno status  (attualmente unused)
  SSIGN_DEVICE_NIBBLE_FORCE_GPS_ON_DISABLE: "0110", // richiesta al GPS di aggiornare le coordinate 
  SSIGN_COORDS_SOURCE_REAL: "GPSC", // Reali
  SSIGN_COORDS_SOURCE_GW_REAL: "GPSG", // Gateway reali
  SSIGN_COORDS_SOURCE_GW_INITIAL: "GPSIG", // Gateway iniziali
  SSIGN_COORDS_SOURCE_SITE: "GPSS", // Sito/Cantiere

  FEATURE_MOBILE_MM: "MOBILE_MM",

  FEATURE_WEB_OPER_MENU: "WEB_OPER_MENU",
  FEATURE_WEB_OPER_DASH: "WEB_OPER_DASH",
  // DEMO_SAIPEM_REQ_04 - A. Stefanutti
  // Dashboard per situazione consegne DPI.
  FEATURE_WEB_OPER_DASH_DPI: "WEB_OPER_DPI_DASH",
  // DEMO_SAIPEM_REQ_04 - A. Stefanutti - FINE
  FEATURE_WEB_OPER_RT_MENU: "WEB_OPER_RT", // WEB_VIEW_DASH_RT
  FEATURE_WEB_OPER_RT_EVENTS: "WEB_OPER_RT_EVENTS", // WEB_VIEW_DASH_RT_EVENTS
  FEATURE_WEB_OPER_RT_EVENTS_JOBS: "WEB_OPER_RT_EVENTS_JOBS", // WEB_VIEW_DASH_RT_JOBS
  FEATURE_WEB_OPER_RT_EVENTS_ALERTS: "WEB_OPER_RT_EVENTS_ALERTS", // WEB_VIEW_DASH_RT_ALERTS
  FEATURE_WEB_OPER_RT_EVENTS_YARDS: "WEB_OPER_RT_EVENTS_YARDS", // WEB_VIEW_DASH_RT_YARDS
  FEATURE_WEB_OPER_RT_EVENTS_SIGNS: "WEB_OPER_RT_EVENTS_SIGNS", // WEB_VIEW_DASH_RT_SIGNS
  FEATURE_WEB_OPER_UTENTI: "WEB_OPER_UTENTI",
  FEATURE_WEB_OPER_UTENTI_SAW: "WEB_OPER_UTENTI_SAW",
  FEATURE_WEB_OPER_UTENTI_COS: "WEB_OPER_UTENTI_COS",
  FEATURE_WEB_OPER_UTENTI_MM: "WEB_OPER_UTENTI_MM",
  FEATURE_WEB_OPER_RT_TELEMETRY: "WEB_OPER_RT_TELEMETRY", // WEB_VIEW_DASH_RT_TELEMETRY
  FEATURE_WEB_OPER_LAVORI_MENU: "WEB_OPER_LAVORI_MENU",
  FEATURE_WEB_OPER_LAVORI_MONO: "WEB_OPER_LAVORI_MONO",
  FEATURE_WEB_OPER_LAVORI_MULTI: "WEB_OPER_LAVORI_MULTI",
  FEATURE_WEB_OPER_ALLARMI_MENU: "WEB_OPER_ALLARMI_MENU",
  FEATURE_WEB_OPER_ALLARMI: "WEB_OPER_ALLARMI",
  FEATURE_WEB_OPER_CHECK_MENU: "WEB_OPER_CHECK_MENU",
  FEATURE_WEB_OPER_CHECK_QUOT: "WEB_OPER_CHECK_QUOT",
  FEATURE_WEB_OPER_CHECK_MULTIOP: "WEB_OPER_CHECK_MULTIOP",
  FEATURE_WEB_OPER_REPORTS_MENU: "WEB_OPER_REPORTS_MENU",
  FEATURE_WEB_OPER_REPORTS_ALLARMI: "WEB_OPER_REPORTS_ALLARMI",
  FEATURE_WEB_OPER_REPORTS_LAVORI_MONOOP: "WEB_OPER_REPORTS_LAVORI_MONOOP",
  FEATURE_WEB_OPER_REPORTS_LAVORI_MULTIOP: "WEB_OPER_REPORTS_LAVORI_MULTIOP",
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti
  // Report utenti che non hanno fatto il check quotidiano (senza interazione con schedulatore A2A).
  FEATURE_WEB_OPER_REPORTS_OPERATORI_SENZACQ: "WEB_OPER_REPORTS_OPERATORI_SENZACQ",
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti - FINE
  // A. Stefanutti - Nuovo report utenti che hanno ricevuto alert mancato check (https://trello.com/c/EehLb15k)
  FEATURE_WEB_OPER_REPORTS_OPERATORI_CONALERTCHECK: "WEB_OPER_REPORTS_OPERATORI_CONALERTCHECK",
  // A. Stefanutti - Nuovo report utenti che hanno ricevuto alert mancato check (https://trello.com/c/EehLb15k) - FINE
  // A. Stefanutti - Nuovo report operatori senza check multioperatore (https://trello.com/c/EJ3Yc6LB)
  FEATURE_WEB_OPER_REPORTS_OPERATORI_CONALERTCHECKMULTI: "WEB_OPER_REPORTS_OPERATORI_CONALERTCHECKMULTI",
  // A. Stefanutti - Nuovo report operatori senza check multioperatore (https://trello.com/c/EJ3Yc6LB) - FINE
  FEATURE_WEB_OPER_REPORTS_OPERATORI_DOTAZ_INCOMPL: "WEB_OPER_REPORTS_OPERATORI_DOTAZ_INCOMPL",
  FEATURE_WEB_OPER_REPORTS_BATTERIA: "WEB_OPER_REPORTS_BATTERIA",
  FEATURE_WEB_OPER_REPORTS_CHECK_QUOT: "WEB_OPER_REPORTS_CHECK_QUOT",
  FEATURE_WEB_OPER_REPORTS_CHECK_MULTI: "WEB_OPER_REPORTS_CHECK_MULTI",

  // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9)
  FEATURE_WEB_OPER_REPORTSAGGR_MENU: "WEB_OPER_REPORTSAGGR_MENU",
  FEATURE_WEB_OPER_REPORTSAGGR_CHECK_QUOT_MENU: "WEB_OPER_REPORTSAGGR_CHECK_QUOT_MENU",
  FEATURE_WEB_OPER_REPORTSAGGR_CHECK_MULTIOP_MENU: "WEB_OPER_REPORTSAGGR_CHECK_MULTIOP_MENU",
  FEATURE_WEB_OPER_REPORTSAGGR_CHECK_MONOOP_MENU: "WEB_OPER_REPORTSAGGR_CHECK_MONOOP_MENU",
  FEATURE_WEB_OPER_REPORTSAGGR_A_ASSETTO_ESITO: "WEB_OPER_REPORTSAGGR_A_ASSETTO_ESITO",
  FEATURE_WEB_OPER_REPORTSAGGR_B_ANOM_ASSETTO_GIUST: "WEB_OPER_REPORTSAGGR_B_ANOM_ASSETTO_GIUST",
  FEATURE_WEB_OPER_REPORTSAGGR_C_ANOM_ASSETTO_TIPODPI: "WEB_OPER_REPORTSAGGR_C_ANOM_ASSETTO_TIPODPI",
  FEATURE_WEB_OPER_REPORTSAGGR_D_ASSETTO_COMPON_ESITO: "WEB_OPER_REPORTSAGGR_D_ASSETTO_COMPON_ESITO",
  FEATURE_WEB_OPER_REPORTSAGGR_E_ANOM_ASSETTO_GIUST: "WEB_OPER_REPORTSAGGR_E_ANOM_ASSETTO_GIUST",
  FEATURE_WEB_OPER_REPORTSAGGR_F_ANOM_ASSETTO_TIPODPI: "WEB_OPER_REPORTSAGGR_F_ANOM_ASSETTO_TIPODPI",
  FEATURE_WEB_OPER_REPORTSAGGR_G_ASSETTO_ESITO: "WEB_OPER_REPORTSAGGR_G_ASSETTO_ESITO",
  FEATURE_WEB_OPER_REPORTSAGGR_H_ANOM_ASSETTO_SOLUZ: "WEB_OPER_REPORTSAGGR_H_ANOM_ASSETTO_SOLUZ",
  FEATURE_WEB_OPER_REPORTSAGGR_I_ANOM_ASSETTO_TIPODPI: "WEB_OPER_REPORTSAGGR_I_ANOM_ASSETTO_TIPODPI",
  // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9) - FINE
  
  // DEMO_SAIPEM_REQ_01 - A. Stefanutti
  // Verbale consegna DPI.
  FEATURE_WEB_OPER_DPI_MENU: "WEB_OPER_DPI_MENU",
  FEATURE_WEB_OPER_DPI: "WEB_OPER_DPI",
  FEATURE_WEB_OPER_DPI_VERBALI: "WEB_OPER_DPI_VERBALI",
  // A. Stefanutti - Matrice Idoneità - Tipi di DPI
  FEATURE_WEB_OPER_DPI_MATRICE_IDONTIPIDPI: "WEB_OPER_DPI_MATRICE_IDONTIPIDPI",
  // A. Stefanutti - Matrice Idoneità - Tipi di DPI - FINE
  // DEMO_SAIPEM_REQ_01 - A. Stefanutti - FINE
  FEATURE_WEB_OPER_DPI_STORICO_ASSEGNAZIONI: "WEB_OPER_DPI_STORICO_ASSEGNAZIONI",
  FEATURE_WEB_OPER_BB_MENU: "WEB_VIEW_LOG_MENU",
  FEATURE_WEB_OPER_BB_REG: "WEB_VIEW_LOG_REG",
  
  FEATURE_WEB_OPER_ORDINI: "WEB_OPER_ORDINI",
  FEATURE_WEB_OPER_CLIENTI: "WEB_OPER_CLIENTI",
  FEATURE_WEB_OPER_SCHEDACLIENTE: "WEB_OPER_SCHEDACLIENTE",
  FEATURE_AZIONE_ORDINI_ASSEGNA: "AZIONE_ORDINI_ASSEGNA",
  FEATURE_AZIONE_ORDINI_CAMBIAASSEGNAZIONE: "AZIONE_ORDINI_CAMBIAASSEGNAZIONE",
  FEATURE_AZIONE_ORDINI_ACCETTA: "AZIONE_ORDINI_ACCETTA",
  FEATURE_AZIONE_ORDINI_RIFIUTA: "AZIONE_ORDINI_RIFIUTA",
  FEATURE_AZIONE_ORDINI_CANCELLA: "AZIONE_ORDINI_CANCELLA",

  FEATURE_WEB_ADMIN_MENU: "WEB_ADMIN_MENU",
  FEATURE_WEB_ADMIN_SIST_MENU: "WEB_ADMIN_SIST_MENU",
  FEATURE_WEB_ADMIN_ATTREZZ_STATI_GEST: "WEB_ADMIN_ATTREZZ_STATI_GEST",

  FEATURE_WEB_ADMIN_FUNZ_GEST: "WEB_ADMIN_FUNZ_GEST",
  FEATURE_WEB_ADMIN_FUNZ_GEST_ADD: "WEB_ADMIN_FUNZ_GEST_ADD",
  FEATURE_WEB_ADMIN_FUNZ_GEST_EDIT: "WEB_ADMIN_FUNZ_GEST_EDIT",
  FEATURE_WEB_ADMIN_FUNZ_GEST_DELETE: "WEB_ADMIN_FUNZ_GEST_DELETE",

  FEATURE_WEB_ADMIN_LOGLEV_GEST: "WEB_ADMIN_LOGLEV_GEST",
  FEATURE_WEB_ADMIN_MODULI_GEST: "WEB_ADMIN_MODULI_GEST",
  FEATURE_WEB_ADMIN_MODULI_GEST_ADD: "WEB_ADMIN_MODULI_GEST_ADD",
  FEATURE_WEB_ADMIN_MODULI_GEST_EDIT: "WEB_ADMIN_MODULI_GEST_EDIT",
  FEATURE_WEB_ADMIN_MODULI_GEST_DELETE: "WEB_ADMIN_MODULI_GEST_DELETE",

  FEATURE_WEB_ADMIN_NOT_MENU: "WEB_ADMIN_NOT_MENU",
  FEATURE_WEB_ADMIN_NOT_CANALI_GEST: "WEB_ADMIN_NOT_CANALI_GEST",
  FEATURE_WEB_ADMIN_NOT_CANALI_GEST_ADD: "WEB_ADMIN_NOT_CANALI_GEST_ADD",
  FEATURE_WEB_ADMIN_NOT_CANALI_GEST_EDIT: "WEB_ADMIN_NOT_CANALI_GEST_EDIT",
  FEATURE_WEB_ADMIN_NOT_CANALI_GEST_DELETE: "WEB_ADMIN_NOT_CANALI_GEST_DELETE",
  FEATURE_WEB_ADMIN_NOT_LIVELLI_GEST: "WEB_ADMIN_NOT_LIVELLI_GEST",
  FEATURE_WEB_ADMIN_NOT_LIVELLI_GEST_ADD: "WEB_ADMIN_NOT_LIVELLI_GEST_ADD",
  FEATURE_WEB_ADMIN_NOT_LIVELLI_GEST_EDIT: "WEB_ADMIN_NOT_LIVELLI_GEST_EDIT",
  FEATURE_WEB_ADMIN_NOT_LIVELLI_GEST_DELETE: "WEB_ADMIN_NOT_LIVELLI_GEST_DELETE",

  FEATURE_WEB_ADMIN_COMP_GEST: "WEB_ADMIN_COMP_GEST",
  FEATURE_WEB_ADMIN_COMP_GEST_ADD: "WEB_ADMIN_COMP_GEST_ADD",
  FEATURE_WEB_ADMIN_COMP_GEST_EDIT: "WEB_ADMIN_COMP_GEST_EDIT",
  FEATURE_WEB_ADMIN_COMP_GEST_DELETE: "WEB_ADMIN_COMP_GEST_DELETE",

  // A2A EVOLUTIVE - Req. 2.1 - A. Stefanutti
  // Differenziazione dell'assetto di riferimento di ogni utente in funzione della sua mansione.
  FEATURE_WEB_ADMIN_IDON_GEST: "WEB_ADMIN_IDON_GEST",
  FEATURE_WEB_ADMIN_IDON_GEST_ADD: "WEB_ADMIN_IDON_GEST_ADD",
  FEATURE_WEB_ADMIN_IDON_GEST_EDIT: "WEB_ADMIN_IDON_GEST_EDIT",
  FEATURE_WEB_ADMIN_IDON_GEST_DELETE: "WEB_ADMIN_IDON_GEST_DELETE",

  FEATURE_WEB_ADMIN_RISCHI_GEST: "WEB_ADMIN_RISCHI_GEST",
  FEATURE_WEB_ADMIN_RISCHI_GEST_ADD: "WEB_ADMIN_RISCHI_GEST_ADD",
  FEATURE_WEB_ADMIN_RISCHI_GEST_EDIT: "WEB_ADMIN_RISCHI_GEST_EDIT",
  FEATURE_WEB_ADMIN_RISCHI_GEST_DELETE: "WEB_ADMIN_RISCHI_GEST_DELETE",
  // A2A EVOLUTIVE - Req. 2.1 - A. Stefanutti - FINE

  FEATURE_WEB_ADMIN_RUOLI_GEST: "WEB_ADMIN_RUOLI_GEST",
  FEATURE_WEB_ADMIN_RUOLI_GEST_ADD: "WEB_ADMIN_RUOLI_GEST_ADD",
  FEATURE_WEB_ADMIN_RUOLI_GEST_EDIT: "WEB_ADMIN_RUOLI_GEST_EDIT",
  FEATURE_WEB_ADMIN_RUOLI_GEST_DELETE: "WEB_ADMIN_RUOLI_GEST_DELETE",

  FEATURE_WEB_ADMIN_UTENTI_MENU: "WEB_ADMIN_UTENTI_MENU",
  FEATURE_WEB_ADMIN_UTENTI_GEST: "WEB_ADMIN_UTENTI_GEST",
  FEATURE_WEB_ADMIN_UTENTI_GEST_ADD: "WEB_ADMIN_UTENTI_GEST_ADD",
  FEATURE_WEB_ADMIN_UTENTI_GEST_EDIT: "WEB_ADMIN_UTENTI_GEST_EDIT",
  FEATURE_WEB_ADMIN_UTENTI_GEST_DELETE: "WEB_ADMIN_UTENTI_GEST_DELETE",
  FEATURE_WEB_ADMIN_UTENTI_GEST_RESETPWD: "WEB_ADMIN_UTENTI_GEST_RESETPWD",

  FEATURE_WEB_VIEW_LOG_MENU: "WEB_VIEW_LOG_MENU",
  FEATURE_WEB_VIEW_LOG_REG: "WEB_VIEW_LOG_REG",

  FEATURE_WEB_ADMIN_PROFILI_GEST: "WEB_ADMIN_PROFILI_GEST",
  FEATURE_WEB_ADMIN_PROFILI_GEST_ADD: "WEB_ADMIN_PROFILI_GEST_ADD",
  FEATURE_WEB_ADMIN_PROFILI_GEST_EDIT: "WEB_ADMIN_PROFILI_GEST_EDIT",
  FEATURE_WEB_ADMIN_PROFILI_GEST_DELETE: "WEB_ADMIN_PROFILI_GEST_DELETE",

  FEATURE_WEB_ADMIN_SOCIETA_MENU: "WEB_ADMIN_SOCIETA_MENU",
  FEATURE_WEB_ADMIN_SOCIETA_GEST: "WEB_ADMIN_SOCIETA_GEST",
  FEATURE_WEB_ADMIN_SOCIETA_GEST_ADD: "WEB_ADMIN_SOCIETA_GEST_ADD",
  FEATURE_WEB_ADMIN_SOCIETA_GEST_EDIT: "WEB_ADMIN_SOCIETA_GEST_EDIT",
  FEATURE_WEB_ADMIN_SOCIETA_GEST_DELETE: "WEB_ADMIN_SOCIETA_GEST_DELETE",

  FEATURE_WEB_ADMIN_STABIL_GEST: "WEB_ADMIN_STABIL_GEST",
  FEATURE_WEB_ADMIN_STABIL_GEST_ADD: "WEB_ADMIN_STABIL_GEST_ADD",
  FEATURE_WEB_ADMIN_STABIL_GEST_EDIT: "WEB_ADMIN_STABIL_GEST_EDIT",
  FEATURE_WEB_ADMIN_STABIL_GEST_DELETE: "WEB_ADMIN_STABIL_GEST_DELETE",

  FEATURE_WEB_ADMIN_UBIC_GEST: "WEB_ADMIN_UBIC_GEST",
  FEATURE_WEB_ADMIN_UBIC_GEST_ADD: "WEB_ADMIN_UBIC_GEST_ADD",
  FEATURE_WEB_ADMIN_UBIC_GEST_EDIT: "WEB_ADMIN_UBIC_GEST_EDIT",
  FEATURE_WEB_ADMIN_UBIC_GEST_DELETE: "WEB_ADMIN_UBIC_GEST_DELETE",

  FEATURE_WEB_ADMIN_AC_GEST: "WEB_ADMIN_AC_GEST",
  FEATURE_WEB_ADMIN_AC_GEST_ADD: "WEB_ADMIN_AC_GEST_ADD",
  FEATURE_WEB_ADMIN_AC_GEST_EDIT: "WEB_ADMIN_AC_GEST_EDIT",
  FEATURE_WEB_ADMIN_AC_GEST_DELETE: "WEB_ADMIN_AC_GEST_DELETE",

  FEATURE_WEB_ADMIN_ATTREZZ_MENU: "WEB_ADMIN_ATTREZZ_MENU",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_002, REQ_FUN_DPIATTR_003
  // REQ_TEC_DPIATTR_WEB_003, REQ_TEC_DPIATTR_WEB_004
  // Creare Amministrazione classi con wizard e associazione agli attributi.
  FEATURE_WEB_ADMIN_CLASSI_GEST: "WEB_ADMIN_CLASSI_GEST",
  FEATURE_WEB_ADMIN_CLASSI_GEST_ADD: "WEB_ADMIN_CLASSI_GEST_ADD",
  FEATURE_WEB_ADMIN_CLASSI_GEST_EDIT: "WEB_ADMIN_CLASSI_GEST_EDIT",
  FEATURE_WEB_ADMIN_CLASSI_GEST_DELETE: "WEB_ADMIN_CLASSI_GEST_DELETE",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE
  FEATURE_WEB_ADMIN_TIPIATTREZZ_GEST: "WEB_ADMIN_TIPIATTREZZ_GEST",
  FEATURE_WEB_ADMIN_TIPIATTREZZ_GEST_ADD: "WEB_ADMIN_TIPIATTREZZ_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIATTREZZ_GEST_EDIT: "WEB_ADMIN_TIPIATTREZZ_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPIATTREZZ_GEST_DELETE: "WEB_ADMIN_TIPIATTREZZ_GEST_DELETE",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_008, REQ_FUN_DPIATTR_009, REQ_FUN_DPIATTR_010
  // REQ_TEC_DPIATTR_WEB_009, REQ_TEC_DPIATTR_WEB_010, REQ_TEC_DPIATTR_WEB_011
  // Creare Amministrazione modelli.
  FEATURE_WEB_ADMIN_MODELLI_GEST: "WEB_ADMIN_MODELLI_GEST",
  FEATURE_WEB_ADMIN_MODELLI_GEST_ADD: "WEB_ADMIN_MODELLI_GEST_ADD",
  FEATURE_WEB_ADMIN_MODELLI_GEST_EDIT: "WEB_ADMIN_MODELLI_GEST_EDIT",
  FEATURE_WEB_ADMIN_MODELLI_GEST_DELETE: "WEB_ADMIN_MODELLI_GEST_DELETE",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE
  FEATURE_WEB_ADMIN_ATTRIB_GEST: "WEB_ADMIN_ATTRIB_GEST",
  FEATURE_WEB_ADMIN_ATTRIB_GEST_ADD: "WEB_ADMIN_ATTRIB_GEST_ADD",
  FEATURE_WEB_ADMIN_ATTRIB_GEST_EDIT: "WEB_ADMIN_ATTRIB_GEST_EDIT",
  FEATURE_WEB_ADMIN_ATTRIB_GEST_DELETE: "WEB_ADMIN_ATTRIB_GEST_DELETE",
  FEATURE_WEB_ADMIN_ATTREZZ_GEST: "WEB_ADMIN_ATTREZZ_GEST",
  FEATURE_WEB_ADMIN_ATTREZZ_GEST_ADD: "WEB_ADMIN_ATTREZZ_GEST_ADD",
  FEATURE_WEB_ADMIN_ATTREZZ_GEST_EDIT: "WEB_ADMIN_ATTREZZ_GEST_EDIT",
  FEATURE_WEB_ADMIN_ATTREZZ_GEST_DELETE: "WEB_ADMIN_ATTREZZ_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPISCHEDA_GEST: "WEB_ADMIN_TIPISCHEDA_GEST",
  FEATURE_WEB_ADMIN_TIPISCHEDA_GEST_ADD: "WEB_ADMIN_TIPISCHEDA_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPISCHEDA_GEST_EDIT: "WEB_ADMIN_TIPISCHEDA_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPISCHEDA_GEST_DELETE: "WEB_ADMIN_TIPISCHEDA_GEST_DELETE",
  FEATURE_WEB_ADMIN_TASKS_GEST: "WEB_ADMIN_TASKS_GEST",
  FEATURE_WEB_ADMIN_TASKS_GEST_ADD: "WEB_ADMIN_TASKS_GEST_ADD",
  FEATURE_WEB_ADMIN_TASKS_GEST_EDIT: "WEB_ADMIN_TASKS_GEST_EDIT",
  FEATURE_WEB_ADMIN_TASKS_GEST_DELETE: "WEB_ADMIN_TASKS_GEST_DELETE",

  FEATURE_WEB_ADMIN_ZONE_GEST: "WEB_ADMIN_ZONE_GEST",
  FEATURE_WEB_ADMIN_ZONE_GEST_ADD: "WEB_ADMIN_ZONE_GEST_ADD",
  FEATURE_WEB_ADMIN_ZONE_GEST_EDIT: "WEB_ADMIN_ZONE_GEST_EDIT",
  FEATURE_WEB_ADMIN_ZONE_GEST_DELETE: "WEB_ADMIN_ZONE_GEST_DELETE",

  FEATURE_WEB_ADMIN_CONFIG_GEST: "WEB_ADMIN_CONFIG_GEST",
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq)
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_BB: "WEB_ADMIN_CONFIG_GEST_LIST_BB",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_COS: "WEB_ADMIN_CONFIG_GEST_LIST_COS",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_COSRT: "WEB_ADMIN_CONFIG_GEST_LIST_COSRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_LDC: "WEB_ADMIN_CONFIG_GEST_LIST_LDC",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_MM: "WEB_ADMIN_CONFIG_GEST_LIST_MM",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_SAW: "WEB_ADMIN_CONFIG_GEST_LIST_SAW",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_SAWRT: "WEB_ADMIN_CONFIG_GEST_LIST_SAWRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_SSIGN: "WEB_ADMIN_CONFIG_GEST_LIST_SSIGN",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_SSIGNRT: "WEB_ADMIN_CONFIG_GEST_LIST_SSIGNRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_SYS: "WEB_ADMIN_CONFIG_GEST_LIST_SYS",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_UAT: "WEB_ADMIN_CONFIG_GEST_LIST_UAT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_BB: "WEB_ADMIN_CONFIG_GEST_EDIT_BB",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_COS: "WEB_ADMIN_CONFIG_GEST_EDIT_COS",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_COSRT: "WEB_ADMIN_CONFIG_GEST_EDIT_COSRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_LDC: "WEB_ADMIN_CONFIG_GEST_EDIT_LDC",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_MM: "WEB_ADMIN_CONFIG_GEST_EDIT_MM",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_SAW: "WEB_ADMIN_CONFIG_GEST_EDIT_SAW",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_SAWRT: "WEB_ADMIN_CONFIG_GEST_EDIT_SAWRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_SSIGN: "WEB_ADMIN_CONFIG_GEST_EDIT_SSIGN",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_SSIGNRT: "WEB_ADMIN_CONFIG_GEST_EDIT_SSIGNRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_SYS: "WEB_ADMIN_CONFIG_GEST_EDIT_SYS",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_UAT: "WEB_ADMIN_CONFIG_GEST_EDIT_UAT",
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq) - FINE
  FEATURE_WEB_ADMIN_CONFIG_GEST_ADD: "WEB_ADMIN_CONFIG_GEST_ADD",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT: "WEB_ADMIN_CONFIG_GEST_EDIT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_DELETE: "WEB_ADMIN_CONFIG_GEST_DELETE",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LICENSE: "WEB_ADMIN_CONFIG_GEST_LICENSE",
  // A. Stefanutti - Restart servizio invio CID a Tibco da Web UI - (https://trello.com/c/NZ09YMq2)
  FEATURE_WEB_ADMIN_CONFIG_STARTSTOP: "WEB_ADMIN_CONFIG_STARTSTOP",
  FEATURE_WEB_OPER_DATADELETE: "WEB_OPER_DATADELETE",
  FEATURE_AZIONE_DATADELETE_START: "AZIONE_DATADELETE_START",
  FEATURE_AZIONE_DATADELETE_STOP: "AZIONE_DATADELETE_STOP",
  FEATURE_AZIONE_DATADELETE_RESTART: "AZIONE_DATADELETE_RESTART",
  FEATURE_WEB_OPER_TIBCOCIDS: "WEB_OPER_TIBCOCIDS",
  FEATURE_AZIONE_TIBCOCIDS_START: "AZIONE_TIBCOCIDS_START",
  FEATURE_AZIONE_TIBCOCIDS_STOP: "AZIONE_TIBCOCIDS_STOP",
  FEATURE_AZIONE_TIBCOCIDS_RESTART: "AZIONE_TIBCOCIDS_RESTART",
  // A. Stefanutti - Restart servizio invio CID a Tibco da Web UI - (https://trello.com/c/NZ09YMq2) - FINE

  FEATURE_WEB_ADMIN_TIPIOP_GEST: "WEB_ADMIN_TIPIOP_GEST",
  FEATURE_WEB_ADMIN_TIPIOP_GEST_ADD: "WEB_ADMIN_TIPIOP_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIOP_GEST_EDIT: "WEB_ADMIN_TIPIOP_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPIOP_GEST_DELETE: "WEB_ADMIN_TIPIOP_GEST_DELETE",
  
  FEATURE_WEB_ADMIN_TIPICAMPO_GEST: "WEB_ADMIN_TIPICAMPO_GEST",
  FEATURE_WEB_ADMIN_TIPICAMPO_GEST_ADD: "WEB_ADMIN_TIPICAMPO_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPICAMPO_GEST_EDIT: "WEB_ADMIN_TIPICAMPO_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPICAMPO_GEST_DELETE: "WEB_ADMIN_TIPICAMPO_GEST_DELETE",

  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_001
  // REQ_TEC_DPIATTR_WEB_001
  // Modificare Amministrazione tipi campo (dato) - lasciare SOLO vista tabellare
  // - Rinominato tutto in "Dato" invece di "Campo"
  FEATURE_WEB_ADMIN_TIPIDATO_GEST: "WEB_ADMIN_TIPIDATO_GEST",
  FEATURE_WEB_ADMIN_TIPIDATO_GEST_ADD: "WEB_ADMIN_TIPIDATO_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIDATO_GEST_EDIT: "WEB_ADMIN_TIPIDATO_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPIDATO_GEST_DELETE: "WEB_ADMIN_TIPIDATO_GEST_DELETE",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE

  FEATURE_WEB_ADMIN_TIPIATTIV_GEST: "WEB_ADMIN_TIPIATTIV_GEST",
  FEATURE_WEB_ADMIN_TIPIATTIV_GEST_ADD: "WEB_ADMIN_TIPIATTIV_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIATTIV_GEST_EDIT: "WEB_ADMIN_TIPIATTIV_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPIATTIV_GEST_DELETE: "WEB_ADMIN_TIPIATTIV_GEST_DELETE",

  FEATURE_WEB_ADMIN_ALLARMI_MENU: "WEB_ADMIN_ALLARMI_MENU",
  FEATURE_WEB_ADMIN_TIPIALLARME_GEST: "WEB_ADMIN_TIPIALLARME_GEST",
  FEATURE_WEB_ADMIN_TIPIALLARME_GEST_ADD: "WEB_ADMIN_TIPIALLARME_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIALLARME_GEST_EDIT: "WEB_ADMIN_TIPIALLARME_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPIALLARME_GEST_DELETE: "WEB_ADMIN_TIPIALLARME_GEST_EDIT",
  FEATURE_WEB_ADMIN_SOLUZ_GEST: "WEB_ADMIN_SOLUZ_GEST",
  FEATURE_WEB_ADMIN_SOLUZ_GEST_ADD: "WEB_ADMIN_SOLUZ_GEST_ADD",
  FEATURE_WEB_ADMIN_SOLUZ_GEST_EDIT: "WEB_ADMIN_SOLUZ_GEST_DELETE",
  FEATURE_WEB_ADMIN_SOLUZ_GEST_DELETE: "WEB_ADMIN_SOLUZ_GEST_EDIT",

  FEATURE_WEB_ADMIN_LIVLOG_GEST: "WEB_ADMIN_LIVLOG_GEST",
  FEATURE_WEB_ADMIN_LIVLOG_GEST_ADD: "WEB_ADMIN_LIVLOG_GEST_ADD",
  FEATURE_WEB_ADMIN_LIVLOG_GEST_EDIT: "WEB_ADMIN_LIVLOG_GEST_DELETE",
  FEATURE_WEB_ADMIN_LIVLOG_GEST_DELETE: "WEB_ADMIN_LIVLOG_GEST_EDIT",
  
  FEATURE_WEB_ADMIN_PROT_GEST: "WEB_ADMIN_PROT_GEST",
  FEATURE_WEB_ADMIN_PROT_GEST_ADD: "WEB_ADMIN_PROT_GEST_ADD",
  FEATURE_WEB_ADMIN_PROT_GEST_EDIT: "WEB_ADMIN_PROT_GEST_DELETE",
  FEATURE_WEB_ADMIN_PROT_GEST_DELETE: "WEB_ADMIN_PROT_GEST_EDIT",
  
  FEATURE_WEB_ADMIN_TECN_GEST: "WEB_ADMIN_TECN_GEST",
  FEATURE_WEB_ADMIN_TECN_GEST_ADD: "WEB_ADMIN_TECN_GEST_ADD",
  FEATURE_WEB_ADMIN_TECN_GEST_EDIT: "WEB_ADMIN_TECN_GEST_DELETE",
  FEATURE_WEB_ADMIN_TECN_GEST_DELETE: "WEB_ADMIN_TECN_GEST_EDIT",

  // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9)
  FEATURE_WEB_ADMIN_TIPIANOM_GEST: "WEB_ADMIN_TIPIANOM_GEST",
  FEATURE_WEB_ADMIN_TIPIANOM_GEST_ADD: "WEB_ADMIN_TIPIANOM_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIANOM_GEST_EDIT: "WEB_ADMIN_TIPIANOM_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPIANOM_GEST_DELETE: "WEB_ADMIN_TIPIANOM_GEST_EDIT",

  FEATURE_WEB_ADMIN_MOTIV_GEST: "WEB_ADMIN_MOTIV_GEST",
  FEATURE_WEB_ADMIN_MOTIV_GEST_ADD: "WEB_ADMIN_MOTIV_GEST_ADD",
  FEATURE_WEB_ADMIN_MOTIV_GEST_EDIT: "WEB_ADMIN_MOTIV_GEST_DELETE",
  FEATURE_WEB_ADMIN_MOTIV_GEST_DELETE: "WEB_ADMIN_MOTIV_GEST_EDIT",
  // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9) - FINE

  FEATURE_WEB_ADMIN_TIPIASS_GEST: "WEB_ADMIN_TIPIASS_GEST",
  FEATURE_WEB_ADMIN_TIPIASS_GEST_ADD: "WEB_ADMIN_TIPIASS_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIASS_GEST_EDIT: "WEB_ADMIN_TIPIASS_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPIASS_GEST_DELETE: "WEB_ADMIN_TIPIASS_GEST_EDIT",

  FEATURE_WEB_ADMIN_TIPILAV_GEST: "WEB_ADMIN_TIPILAV_GEST",
  FEATURE_WEB_ADMIN_TIPILAV_GEST_ADD: "WEB_ADMIN_TIPILAV_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPILAV_GEST_EDIT: "WEB_ADMIN_TIPILAV_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPILAV_GEST_DELETE: "WEB_ADMIN_TIPILAV_GEST_EDIT",

  FEATURE_WEB_ADMIN_DPI_MENU: "WEB_ADMIN_DPI_MENU",
  FEATURE_WEB_ADMIN_ASS_GEST: "WEB_ADMIN_ASS_GEST",
  FEATURE_WEB_ADMIN_ASS_GEST_ADD: "WEB_ADMIN_ASS_GEST_ADD",
  FEATURE_WEB_ADMIN_ASS_GEST_EDIT: "WEB_ADMIN_ASS_GEST_DELETE",
  FEATURE_WEB_ADMIN_ASS_GEST_DELETE: "WEB_ADMIN_ASS_GEST_EDIT",

  FEATURE_WEB_ADMIN_TIPITELEM_GEST: "WEB_ADMIN_TIPITELEM_GEST",
  FEATURE_WEB_ADMIN_TIPITELEM_GEST_ADD: "WEB_ADMIN_TIPITELEM_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPITELEM_GEST_EDIT: "WEB_ADMIN_TIPITELEM_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPITELEM_GEST_DELETE: "WEB_ADMIN_TIPITELEM_GEST_EDIT",

  FEATURE_WEB_ADMIN_TIPIDISP_GEST: "WEB_ADMIN_TIPIDISP_GEST",
  FEATURE_WEB_ADMIN_TIPIDISP_GEST_ADD: "WEB_ADMIN_TIPIDISP_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIDISP_GEST_EDIT: "WEB_ADMIN_TIPIDISP_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPIDISP_GEST_DELETE: "WEB_ADMIN_TIPIDISP_GEST_EDIT",

  FEATURE_WEB_ADMIN_DISP_GEST: "WEB_ADMIN_DISP_GEST",
  FEATURE_WEB_ADMIN_DISP_GEST_ADD: "WEB_ADMIN_DISP_GEST_ADD",
  FEATURE_WEB_ADMIN_DISP_GEST_EDIT: "WEB_ADMIN_DISP_GEST_DELETE",
  FEATURE_WEB_ADMIN_DISP_GEST_DELETE: "WEB_ADMIN_DISP_GEST_EDIT",

  FEATURE_WEB_ADMIN_MEZZI_MENU: "WEB_ADMIN_MEZZI_MENU",
  FEATURE_WEB_ADMIN_MEZZI_GEST: "WEB_ADMIN_MEZZI_GEST",
  FEATURE_WEB_ADMIN_MEZZI_GEST_ADD: "WEB_ADMIN_MEZZI_GEST_ADD",
  FEATURE_WEB_ADMIN_MEZZI_GEST_EDIT: "WEB_ADMIN_MEZZI_GEST_DELETE",
  FEATURE_WEB_ADMIN_MEZZI_GEST_DELETE: "WEB_ADMIN_MEZZI_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPIMEZZI_GEST: "WEB_ADMIN_TIPIMEZZI_GEST",
  FEATURE_WEB_ADMIN_TIPIMEZZI_GEST_ADD: "WEB_ADMIN_TIPIMEZZI_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIMEZZI_GEST_EDIT: "WEB_ADMIN_TIPIMEZZI_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPIMEZZI_GEST_DELETE: "WEB_ADMIN_TIPIMEZZI_GEST_EDIT",

  FEATURE_WEB_ADMIN_ATT_MENU: "WEB_ADMIN_ATT_MENU",
  FEATURE_WEB_ADMIN_ATT_GEST: "WEB_ADMIN_ATT_GEST",
  FEATURE_WEB_ADMIN_ATT_GEST_ADD: "WEB_ADMIN_ATT_GEST_ADD",
  FEATURE_WEB_ADMIN_ATT_GEST_EDIT: "WEB_ADMIN_ATT_GEST_DELETE",
  FEATURE_WEB_ADMIN_ATT_GEST_DELETE: "WEB_ADMIN_ATT_GEST_EDIT",
  FEATURE_WEB_ADMIN_LAV_GEST: "WEB_ADMIN_LAV_GEST",
  FEATURE_WEB_ADMIN_LAV_GEST_ADD: "WEB_ADMIN_LAV_GEST_ADD",
  FEATURE_WEB_ADMIN_LAV_GEST_EDIT: "WEB_ADMIN_LAV_GEST_DELETE",
  FEATURE_WEB_ADMIN_LAV_GEST_DELETE: "WEB_ADMIN_LAV_GEST_EDIT",

  TIPIDPI_DURATA_DEFAULT: 12,

  MODE_ADD: 1,
  MODE_EDIT: 2,
  MODE_VIEW: 3,

  DEFAULT_PAGE_SIZE: 10, // [5, 10, 20, 25, 50, 100]
  DEFAULT_ORDERS_PAGE_SIZE: 5, // [5, 10, 20, 25, 50, 100]

  MSG_KIND_INFO: 1,
  MSG_KIND_SUCCESS: 2,
  MSG_KIND_WARNING: 3,
  MSG_KIND_ERROR: 4,
  MSG_KIND_PRIMARY: 5,
  MSG_KIND_NOTE: 6,

  PROFILE_CODE_SYSADMIN: "SYSADMIN",

  ROLE_CODE_SYSADMIN: "SYSADMIN",
  ROLE_CODE_CLISUP: "CLISUP",
  ROLE_CODE_GESTORDINIADMIN: "GESTORDINIADMIN",
  ROLE_CODE_GESTORDINIAC: "GESTORDINIAC",
  ROLE_CODE_GESTORDINIPIVA: "GESTORDINIPIVA",
  ROLE_CODE_GESTORDINIMANUT: "GESTORDINIMANUT",
  ROLE_CODE_CLIMOB: "CLIMOB",
  ROLE_CODE_MANUMOB: "MANUMOB",
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti
  // Report utenti che non hanno fatto il check quotidiano (senza interazione con schedulatore A2A).
  ROLE_CODE_REPORTSALLZONES: "REPORTSALLZONES",
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti - FINE

  CODICE_ESITO_CONFORME: "CONFORME",
  CODICE_ESITO_NONCONFORME: "NON CONFORME",
  CODICE_ESITO_NONAPPLICABILE: "NON APPLICABILE",

  CODICE_ESITO_CONTROLLI_OK: "ES001",
  CODICE_ESITO_CONTROLLI_KO: "ES002",
  CODICE_ESITO_CONTROLLI_SOSP: "ES003",
  CODICE_ESITO_CONTROLLI_KO_DPI_ALL: "ES004",
  CODICE_ESITO_CONTROLLI_KO_DPI_NON_PRES: "ES005",
  CODICE_ESITO_CONTROLLI_KO_DPI_NON_RIL: "ES006",

  CODICE_TIPO_CAMPO_BOOLEAN: "BOOL",    // Da DB
  CODICE_TIPO_CAMPO_TESTO: "TXT",       // Da DB
  // A. Stefanutti - Sostituzione tipo dato "NUM" con la coppia "INT" e "DECIMAL" (trello https://trello.com/c/GbPTmWxI)
  // Rimosso il tipo di campo numerico generico.
  CODICE_TIPO_CAMPO_DATAORA: "DATAORA", // Da DB
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq)
  CODICE_TIPO_CAMPO_INT: "INT",         // Da DB
  CODICE_TIPO_CAMPO_DECIMAL: "DECIMAL", // Da DB
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq) - FINE

  TIPOASSEGNATARIO_CODE_AC: "AREACENTER",
  TIPOASSEGNATARIO_CODE_PIVA: "PARTITAIVA",
  
  LOGIN_REDIRECT_TIMEOUT: 6000, // millis
  RECUPERO_PWD_TIMEOUT: 10000, // millis

  ORDER_STATUS_CODE_NUOVO: "NUOVO",
  ORDER_STATUS_CODE_ASSEGNATO: "ASSEGNATO",
  ORDER_STATUS_CODE_ACCETTATO: "ACCETTATO",
  ORDER_STATUS_CODE_ACCETTATOAC: "ACCETTATOAC",
  ORDER_STATUS_CODE_INCORSO: "INCORSO",
  ORDER_STATUS_CODE_COMPLETATO: "COMPLETATO",
  ORDER_STATUS_CODE_RIFIUTATO: "RIFIUTATO",
  ORDER_STATUS_CODE_CANCELLATO: "CANCELLATO",

  CODICE_STATO_INTERVENTO_NUOVO: "NUOVO",
  CODICE_STATO_INTERVENTO_COMPLETATO: "COMPLETATO",
  CODICE_SITUAZIONE_INTERVENTO_REGOLARE: 0,
  CODICE_SITUAZIONE_INTERVENTO_INSCADENZA: 100, // Secondo i criteri implementati nel Backend
  CODICE_SITUAZIONE_INTERVENTO_INRITARDO: 200,  // Eseguito in data successiva alla data di scadenza
  CODICE_SITUAZIONE_INTERVENTO_SCADUTO: 300,    // Non ancora eseguito e con data di scadenza nel passato
  
  DEVICEKIND_CODE_DPI_TAG: "TAG",
  DEVICEKIND_CODE_SMARTSIGN_TAG: "SSTAG",
  DEVICEKIND_CODE_SMARTSIGN_GW: "SSGW",
  DEVICEKIND_CODE_SMARTPHONE: "SMARTPA",
	DEVICEKIND_CODE_ABWTRACKER: "ABWTRACKER",
	DEVICEKIND_CODE_EMUTRACKER: "EMUTRACKER",
  DEVICEKIND_CODE_NBTRACKER: "NBTRACKER",
  
  TIPODPI_BELT: "BELT",
  TIPODPI_EARPROTECTORS: "EARPROTECTORS",
  TIPODPI_GAS: "GAS",
  TIPODPI_GLASSES: "GLASSES",
  TIPODPI_GLOVEDX: "GLOVEDX",
  TIPODPI_GLOVEDXNI: "GLOVEDXNI",
  TIPODPI_GLOVESX: "GLOVESX",
  TIPODPI_GLOVESXNI: "GLOVESXNI",
  TIPODPI_HARNESS: "HARNESS",
  TIPODPI_HAT: "HAT",
  TIPODPI_HELMET: "HELMET",
  TIPODPI_HELMETNV: "HELMETNV",
  TIPODPI_JACKET: "JACKET",
  TIPODPI_PANTS: "PANTS",
  TIPODPI_SHOES: "SHOES",
  TIPODPI_UOMOAT: "UOMOAT",
  TIPODPI_VISOR: "VISOR",
  TIPODPI_UNKNOWN: "UNKNOWN",

  SOLUZIONEALLARME_AUTO: "AUTO",
  SOLUZIONEALLARME_END: "END",
  SOLUZIONEALLARME_FALSE: "FALSE",
  SOLUZIONEALLARME_IGNORE: "IGNORE",
  SOLUZIONEALLARME_SUSPEND: "SUSPEND",
  SOLUZIONEALLARME_OK: "OK",
  
  ALERT_KIND_CODE_PPE: "PPE",
  // A. Stefanutti - Aggiunta della gestione tipo allarme PPEOFFLINE
  ALERT_KIND_CODE_PPEOFFLINE: "PPEOFFLINE",
  // A. Stefanutti - Aggiunta della gestione tipo allarme PPEOFFLINE - FINE
  ALERT_KIND_CODE_SMS: "SMS",
  ALERT_KIND_CODE_UAT: "UAT",
  
  TIPOCONTROLLO_CHECKQUOT: "SAWCHECKQUOTID",
  TIPOCONTROLLO_CHECKMULTIOP: "SAWCHECKMULTIOP",

  TIPOLAVOROESEGUITO_MONO: 0,
  TIPOLAVOROESEGUITO_MULTI: 1,

  DUMMY_STRING: "UNKNOWN",

  // A. Stefanutti - Disabilitazione input data scadenza per attrezzature di classe DPI (trello https://trello.com/c/o98Vuzaf).
  CLASS_CODE_DPI: "DPI",
  // A. Stefanutti - Disabilitazione input data scadenza per attrezzature di classe DPI - FINE

  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC)
  STATUS_CODE_ATTIVO: "ATTIVO",
  STATUS_CODE_CANCELLATO: "CANCELLATO",
  STATUS_CODE_DISMESSO: "DISMESSO",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC) - FINE


  // ******************************************************************
  // I18N
  // ******************************************************************

  formats: { // https://github.com/yahoo/react-intl/issues/539
    number: {
      USD: {
        style: 'currency',
        currency: 'USD'
      },
      EUR: {
        style: 'currency',
        currency: 'EUR'
      }
    }
  },
}

export const constants = SFConstants;