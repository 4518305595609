// A. Stefanutti - Modifiche a seguito di UAT A2A 05/2021
// REQ_FUN_UAT_202105_001
// Centralizzazione dello state per i valori di ricerca dei reports.
import * as ActionTypes from "./ActionTypes"
import { constants } from "../config/constants.js"
import moment from 'moment';



const initialState = {
  // A. Stefanutti - Segnalazione sonora delle notifiche in arrivo (trello https://trello.com/c/0pROhUYZ)
  playAudio: false,
  // A. Stefanutti - Segnalazione sonora delle notifiche in arrivo (trello https://trello.com/c/0pROhUYZ) - FINE
  sessionUser: {},
  ppeKinds: [],
  alertSolutions: [],
  zones: [],
  // A. Stefanutti - Cartelli in import massivo dispositivi (https://trello.com/c/DdeG3sYw)
  modules: [],
  // A. Stefanutti - Cartelli in import massivo dispositivi (https://trello.com/c/DdeG3sYw) - FINE
  reportsConfig: {
    start: moment().startOf('day').subtract(1, 'week').valueOf(),
    end: moment().endOf('day').valueOf(),
    period: constants.PERIOD_ULTIMASETTIMANA,
    zones: [],
    selectedZones: [],
    zonesDone: false
  }
}



const Reducer = (state = initialState, action) => {
  switch (action.type) {

    // **************************************************
    // SESSION USER
    // **************************************************

    case ActionTypes.SET_SESSION_USER:
      return {
        ...state,
        sessionUser: action.userData.user,
      }

    // **************************************************
    // PPE KINDS
    // **************************************************

    case ActionTypes.SET_PPE_KINDS:
      return {
        ...state,
        ppeKinds: action.ppeKinds,
      }

    // **************************************************
    // PPE ALERT SOLUTIONS
    // **************************************************

    case ActionTypes.SET_PPE_ALERT_SOLUTIONS:
      return {
        ...state,
        alertSolutions: action.alertSolutions,
      }

    // **************************************************
    // ZONES
    // **************************************************

    case ActionTypes.SET_ZONES:
      return {
        ...state,
        zones: action.zones,
      }

    // A. Stefanutti - Cartelli in import massivo dispositivi (https://trello.com/c/DdeG3sYw)
    // **************************************************
    // MODULES
    // **************************************************

    case ActionTypes.SET_MODULES:
      return {
        ...state,
        modules: action.modules,
      }
    // A. Stefanutti - Cartelli in import massivo dispositivi (https://trello.com/c/DdeG3sYw) - FINE

    // **************************************************
    // REPORTS
    // **************************************************

    case ActionTypes.SET_REPORTS_DATES:
      return {
        ...state,
        reportsConfig: {
          ...state.reportsConfig,
          start: action.start,
          end: action.end
        }
      }

    case ActionTypes.SET_REPORTS_DATE_FROM:
      return {
        ...state,
        reportsConfig: {
          ...state.reportsConfig,
          start: action.date
        }
      }

    case ActionTypes.SET_REPORTS_DATE_TO:
      return {
        ...state,
        reportsConfig: {
          ...state.reportsConfig,
          end: action.date
        }
      }

    case ActionTypes.SET_REPORTS_PERIOD:
      // In base al period, calcola le date da-a
      var now = moment()
      var start
      var end
      if (action.period === constants.PERIOD_TUTTI) {
        // Tutti
        start = 0
        end = 0
      }
      // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9)
      else if (action.period === constants.PERIOD_ULTIMOTRIMESTRE) {
        // Ultimo trimestre
        start = now.clone().startOf('day').subtract(3, 'month').valueOf()
        end = now.endOf('day').valueOf()
      }
      // A. Stefanutti - A2000007_124 - Nuova Reportistica Aggregata Smart DPI per A2A (https://trello.com/c/qnkbHUB9) - FINE
      else if (action.period === constants.PERIOD_ULTIMOMESE) {
        // Ultimo mese
        start = now.clone().startOf('day').subtract(1, 'month').valueOf()
        end = now.endOf('day').valueOf()
      }
      else if (action.period === constants.PERIOD_ULTIMASETTIMANA) {
        // Ultima settimana
        start = now.clone().startOf('day').subtract(1, 'week').valueOf()
        end = now.endOf('day').valueOf()
      }
      else if (action.period === constants.PERIOD_CUSTOM) {
        // Le date restano quelle impostate in precedenza
        start = state.reportsConfig.start
        end = state.reportsConfig.end
      }
      else {
        // Oggi
        start = moment().startOf('day').valueOf();
        end = moment().endOf('day').valueOf();
      }
  
      return {
        ...state,
        reportsConfig: {
          ...state.reportsConfig,
          period: action.period,
          start: start,
          end: end
        }
      }
  
    case ActionTypes.SET_REPORTS_ZONES:
      return {
        ...state,
        reportsConfig: {
          ...state.reportsConfig,
          zones: action.zones ? action.zones : state.reportsConfig.zones,
          selectedZones: action.selectedZones ? action.selectedZones : state.selectedZones,
          zonesDone: action.zonesDone
        }
      }
    

    // **************************************************
    // GENERAL
    // **************************************************

    case ActionTypes.RESET_APP:
      localStorage.clear();
      return initialState

    // A. Stefanutti - Segnalazione sonora delle notifiche in arrivo (trello https://trello.com/c/0pROhUYZ)
    case ActionTypes.SET_PLAY_AUDIO:
      return {
        ...state,
        playAudio: action.playAudio,
      }
    // A. Stefanutti - Segnalazione sonora delle notifiche in arrivo (trello https://trello.com/c/0pROhUYZ) - FINE
    
    default:
      // Do nothing
      return state
  }
}



export default Reducer